import gql from 'graphql-tag'

const deleteProject = gql`
  mutation deleteProject(
    $project_id: Int!
  ){
    deleteProject(
      project_id: $project_id
    ){
      status
      message
    }
  }
`
export default deleteProject
