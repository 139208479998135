import gql from 'graphql-tag'

const quoteDryReportSearch = gql`
    query quoteDryReportSearch(
      $project_id: Int
      $search: String
    ){
      quoteDryReportSearch(
        project_id: $project_id
        search: $search
      ){
        id
        name
        last_version
        total
        total_hpp
        total_volume
        created_at
        project {
          id
          category {
            id
            name
          }
          customer_id
          name
          tgl_reminder
          status
        }
      }
    }
`
export default quoteDryReportSearch
