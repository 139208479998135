import gql from 'graphql-tag'

const dryReportInformation = gql`
  query dryReportInformation(
    $quote_id: [Int]!
  ){
    dryReportInformation (
      quote_id: $quote_id
    ){
      areas{
        category {
          id
          name
        }
        loss
        quote_dft
        items {
          item_id
          item_name
          item_code
          item_type {
            id
            name
          }
          color {
            id_ral
            hex_code
            ind_name
            eng_name
          }
          liter
        }
      }
      items{
        item_id
        quote_liter
        item_name
        item_code
        item_type {
          id
          name
        }
        color {
          id_ral
          hex_code
          ind_name
          eng_name
        }
        data_sheet
      }
    }
  }
`
export default dryReportInformation
