import gql from 'graphql-tag'

const addScheme = gql`
  mutation addScheme(
    $params: paramScheme!
  ){
    addScheme(
      params: $params
    ){
      id
      status
      message
    }
  }
`
export default addScheme
