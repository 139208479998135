import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function errorModal(title) {
  /* eslint-disable no-nested-ternary */
  /* eslint-disable no-prototype-builtins */
  const text = title.graphQLErrors
    ? Array.isArray(title.graphQLErrors)
      ? title.graphQLErrors.length
        ? title.graphQLErrors[0].hasOwnProperty('message')
          ? title.graphQLErrors[0].message
          : title.graphQLErrors
        : 'Terjadi Kesalahan'
      : title.toString()
    : title.toString()

  if (text) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: text,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }
}
