import gql from 'graphql-tag'

const updateSalesOrder = gql`
  mutation updateSalesOrder(
    $sales_order_id: Int!
    $params: paramSalesOrder!
  ){
    updateSalesOrder(
      sales_order_id: $sales_order_id,
      params: $params
    ){
      status
      message
    }
  }
`
export default updateSalesOrder
