import gql from 'graphql-tag'

const updateCustomer = gql`
  mutation updateCustomer(
    $customer_id: String!
    $params: paramCustomer
  ){
    updateCustomer(
      customer_id: $customer_id
      params: $params
    ){
      status
      message
    }
  }
`
export default updateCustomer
