import gql from 'graphql-tag'

const quoteStatuses = gql`
query quoteStatuses {
  quoteStatuses{
    id
    name
  }
}
`
export default quoteStatuses
