const { default: gql } = require('graphql-tag')

const restoreScheme = gql`
  mutation restoreScheme(
    $scheme_id: Int!
  ){
    restoreScheme(
      scheme_id: $scheme_id
    ){
      status
      message
    }
  }
`
export default restoreScheme
