import gql from 'graphql-tag'

const referenceDB = gql`
  query referenceDB {
    referenceDB{
      id
      name
      long_name
      address
      workPhone
      email
      photo_short
      photo_long
    }
  }
`
export default referenceDB
