import gql from 'graphql-tag'

const scheme = gql`
  query scheme(
    $filter: filterScheme
    $pagination: Pagination
  ){
    scheme(
      filter: $filter
      pagination: $pagination
    ){
      count
      schemes {
        id
        color {
          id_ral
          hex_code
          ind_name
          eng_name
        }
        name
        notes
        status
        jum_item
        min_coat
        max_coat
        min_dft
        max_dft
        min_loss
        max_loss
      }
    }
  }
`
export default scheme
