import gql from 'graphql-tag'

const removeProfilePicture = gql`
mutation removeProfilePicture{
  removeProfilePicture{
    status
    message
  }
}
`
export default removeProfilePicture
