import gql from 'graphql-tag'

const schemeDetail = gql`
  query schemeDetail(
    $scheme_id: Int!
  ){
    schemeDetail(
      scheme_id: $scheme_id
    ){
      id
      name
      notes
      status
      items {
        id
        color {
          id_ral
          hex_code
          ind_name
          eng_name
        }
        coat
        detailSellingPrice {
          priceCategory {
            id
            name
          }
          price
        }
        dry_film_thickness
        loss
        vs_volume_solid
        balance
        availableToSell
        name
        no
        itemCategory {
          id
          name
        }
        type {
          id
          name
        }
        liter
        packaging_name
        hpp
        agent_item_id
        ratio_agent
        agent_item_2_id
        ratio_agent_2
        recommended_thinner_id
        ratio_recommended_thinner_id
        unit2Name
        unit3Name
        ratio2
        ratio3
        data_sheet
      },
      color {
        id_ral
        hex_code
        ind_name
        eng_name
      }
      reference_db
    }
  }
`
export default schemeDetail
