import gql from 'graphql-tag'

const surfacePreparations = gql`
  query surfacePreparations{
    surfacePreparations {
      id
      name
    }
  }
`
export default surfacePreparations
