import gql from 'graphql-tag'

const ralColors = gql`
  query{
    ralColors{
      id_ral
      hex_code
      ind_name
      eng_name
    }
  }
`
export default ralColors
