import gql from 'graphql-tag'

const addProject = gql`
  mutation addProject(
    $params: paramProject!
  ){
    addProject(
      params: $params
    ){
      id
      status
      message
    }
  }
`
export default addProject
