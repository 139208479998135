import { apolloClient } from '@/vue-apollo'
import { createHelpers } from 'vuex-map-fields'
import myProject from '@/graphql/queries/myProject'
import {
  dashboardPendingQuotes, dashboardPendingQuotesManager, dashboardPerformance, performancePenjualan, performanceTargetSales, tasksPerformanceChart, tasksPerformanceStats,
} from '@/graphql/query'
import errorModal from '../error-handling'

const { getDashboardData, updateDashboardData } = createHelpers({
  getterType: 'getDashboardData',
  mutationType: 'updateDashboardData',
})

export default {
  state: {
    // project calendar component
    loadingProject: true,
    projectList: [],
    selectedPerson: 'all',

    // performance omzet component
    performanceOmzet: {
      selectedOmzet: 'Q',
      selectedPerson: 'all',
      omzetData: '',
      omzetRupiah: '',
      omzetLiter: '',
      loadingChart: true,
    },

    // sale chart component
    saleChart: {
      viewMode: { label: 'Rupiah', value: 'rp' },
      typeMode: { label: 'Quote', value: 'q' },
      dateFilter: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1),
        endDate: new Date().setHours(0, 0, 0, 0),
      },
      notfound: false,
      loadingChart: false,
      chartData: {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [
          {
            label: 'Total HPP',
            data: Array.from({ length: 40 }, () => Math.floor(Math.random() * 40)),
            borderColor: '#17a2b8',
            type: 'line',
            fill: false,
          },
          {
            label: 'Penjualan Saya Periode Lalu',
            data: Array.from({ length: 40 }, () => Math.floor(Math.random() * 40)),
            backgroundColor: '#ff9f43',
            borderColor: 'transparent',
            stack: 'Stack 0',
            barBottom: true,
          },
          {
            label: 'Penjualan Sales Lain Periode Lalu',
            data: Array.from({ length: 40 }, () => Math.floor(Math.random() * 40)),
            backgroundColor: 'rgba(255,159,67, 0.4)',
            borderColor: 'transparent',
            stack: 'Stack 0',
          },
          {
            label: 'Penjualan Saya Periode Ini',
            data: Array.from({ length: 40 }, () => Math.floor(Math.random() * 40)),
            backgroundColor: '#6DAB3C',
            borderColor: 'transparent',
            stack: 'Stack 1',
            barBottom: true,
          },
          {
            label: 'Penjualan Sales Lain Periode Ini',
            data: Array.from({ length: 40 }, () => Math.floor(Math.random() * 40)),
            backgroundColor: 'rgba(109, 171, 60, 0.4)',
            borderColor: 'transparent',
            stack: 'Stack 1',
          },
        ],
      },
      funnel: {
        series: [
          {
            name: 'Periode Ini',
            data: [40, 30, 20],
          },
          {
            name: 'Periode Lalu',
            data: [-34, -28, -20],
          },
        ],
      },
    },

    // performance task component
    performanceTasks: {
      loading: false,
      chartData: [],
      summaryPeriod: 'Y',
      periodSelection: 'recent',
      summaryData: [],
    },

    // top items component
    topItems: {
      fetchResults: {},
      dateFilter: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 11, 59, 59, 999),
      },
      modeSelection: [
        {
          value: 'items',
          label: 'Top Items',
        },
        {
          value: 'customers',
          label: 'Top Customers',
        },
        {
          value: 'users',
          label: 'Top Users',
        },
      ],
      selectedMode: {
        value: 'items',
        label: 'Top Items',
      },
      topN: 5,
      ref_db: '',
      owned: {
        value: 'all',
        label: 'Semua',
      },
      loading: true,
    },

    // pending quotes component
    pendingQuotes: {
      loading: false,
      quotePendingManager: {},
      quoteList: [],
      mode: 'needapproval',
    },
  },
  getters: {
    getDashboardData,
  },
  mutations: {
    updateDashboardData,
  },
  actions: {
    getMyProject(state, payload) {
      state.commit('updateDashboardData', { path: 'loadingProject', value: true })
      apolloClient.query({
        query: myProject,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
        variables: payload,
      }).then(result => {
        state.commit('updateDashboardData', { path: 'loadingProject', value: false })
        state.commit('updateDashboardData', { path: 'projectList', value: result.data.myProject })
      }).catch(err => {
        errorModal(err)
        state.commit('updateDashboardData', { path: 'loadingProject', value: false })
      })
    },
    getTasksPerformanceChart(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: tasksPerformanceChart,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            user_id: +payload,
          },
        }).then(result => {
          resolve(result.data.tasksPerformanceChart)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getTasksPerformanceStats(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: tasksPerformanceStats,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            user_id: +payload.id,
            period: payload.period,
          },
        }).then(result => {
          resolve(result.data.tasksPerformanceStats)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getPendingQuotes(state) {
      state.commit('updateDashboardData', { path: 'pendingQuotes.loading', value: true })
      apolloClient.query({
        query: dashboardPendingQuotes,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('updateDashboardData', { path: 'pendingQuotes.quoteList', value: result.data.dashboardPendingQuotes })
        state.commit('updateDashboardData', { path: 'pendingQuotes.loading', value: false })
      }).catch(err => {
        errorModal(err)
        state.commit('updateDashboardData', { path: 'pendingQuotes.loading', value: false })
      })
    },
    getPendingQuotesManager(state) {
      state.commit('updateDashboardData', { path: 'pendingQuotes.loading', value: true })
      apolloClient.query({
        query: dashboardPendingQuotesManager,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('updateDashboardData', { path: 'pendingQuotes.quotePendingManager', value: result.data.dashboardPendingQuotesManager })
        state.commit('updateDashboardData', { path: 'pendingQuotes.quoteList', value: result.data.dashboardPendingQuotesManager.all_submitted })
        state.commit('updateDashboardData', { path: 'pendingQuotes.loading', value: false })
      }).catch(err => {
        errorModal(err)
        state.commit('updateDashboardData', { path: 'pendingQuotes.loading', value: false })
      })
    },
    getDashboardPerformance(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: dashboardPerformance,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            filter: payload,
          },
        }).then(result => {
          resolve(result.data.dashboardPerformance)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getPerformanceTargetSales(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: performanceTargetSales,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            filter: payload,
          },
        }).then(result => {
          resolve(result.data.performanceTargetSales)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getPerformancePenjualan(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: performancePenjualan,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            filter: payload,
          },
        }).then(result => {
          resolve(result.data.performancePenjualan)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
