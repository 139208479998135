import gql from 'graphql-tag'

const items = gql`
  query items(
    $filter: filterItem
    $pagination: Pagination
    ) {
      items(
        filter: $filter
        pagination: $pagination
      ){
        count
        items{
          id
          balance
          availableToSell
          name
          no
          itemCategory {
            id
            name
          }
          vs_volume_solid
          color {
            id_ral
            hex_code
            ind_name
            eng_name
          }
          packaging_name
          liter
          agent_item_id
          recommended_thinner_id
          ratio_recommended_thinner_id
          type {
            id
            name
          }
          isInDB
        }
      }}
    `
export default items
