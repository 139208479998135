import gql from 'graphql-tag'

const deleteSalesOrderAttachment = gql`
  mutation deleteSalesOrderAttachment(
    $attachment_id: Int!
  ){
    deleteSalesOrderAttachment(
      attachment_id: $attachment_id
    ) {
      status
      message
    }
  }
`
export default deleteSalesOrderAttachment
