import gql from 'graphql-tag'

const deleteTask = gql`
  mutation deleteTask(
    $task_id: Int!
  ){
    deleteTask(task_id: $task_id) {
      status
      message
    }
  }
`
export default deleteTask
