import gql from 'graphql-tag'

const updateProjectReminder = gql`
  mutation updateProjectReminder(
    $project_id: Int!
    $tgl_reminder: DateTime!
  ){  
    updateProjectReminder(
      project_id: $project_id,
      tgl_reminder: $tgl_reminder,
    ){
      status
      message
    }
  }
`
export default updateProjectReminder
