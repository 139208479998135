import gql from 'graphql-tag'

const salesOrderDetail = gql`
  query  salesOrderDetail(
    $sales_order_id: Int!
  ){
    salesOrderDetail(sales_order_id: $sales_order_id) {
      id
      items {
        id
        name
        no
        unitPrice
        itemCashDiscount
        quantity
        totalPrice
        detailTaxName
      }
      transDate
      shipDate
      tax1Amount
      poNumber
      status
      customer {
        id
        name
      }
      shipment {
        id
        name
      }
      number
      description
      subTotal
      cashDiscount
      totalAmount
      toAddress
      paymentTerm {
        id
        name
      }
      userCreate {
        id
        phone
        email
        name
        role {
          id
          name
        }
        photo
        ttd
        status
        target_rupiah
        target_liter
        target_rupiah_2
        target_liter_2
        reference_accurate_id_1
        reference_accurate_id_2
        reference_db {
          id
          name
        }
      }
      attachment {
        id
        name
        extension
        filesizeInMega
        path
      }
    }
  }
`
export default salesOrderDetail
