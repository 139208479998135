import gql from 'graphql-tag'

const addTask = gql`
  mutation addTask(
    $user_id: Int!
    $params: paramTask
  ){
    addTask (
      user_id: $user_id
      params: $params
    ){
      status
      message
    }
  }
`
export default addTask
