import gql from 'graphql-tag'

const salesOrderAttachment = gql`
  mutation salesOrderAttachment(
    $sales_order_id: Int!
    $files: [Upload!]!
  ){
    salesOrderAttachment(
      sales_order_id: $sales_order_id
      files: $files
    ){
      status
      message
    }
  }
`
export default salesOrderAttachment
