<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
    <scroll-to-top />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import { useWindowSize, useCssVar, useOnline } from '@vueuse/core'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

import firebase from 'firebase/app'
import 'firebase/messaging'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotificationSound from '@/assets/audio/notification.wav'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created() {
    /* eslint-disable no-console */
    if (!localStorage.getItem('deviceId')) {
      localStorage.setItem('deviceId', uuidv4())
    }

    Notification.requestPermission().then(() => {
      firebase.messaging().getToken().then(token => {
        localStorage.setItem('fcmToken', token)
        this.receiveMessage()
      })
    })
  },
  mounted() {
    const hasUserData = localStorage.getItem('user')

    if (hasUserData) {
      this.$store.dispatch('initApp')
    }
  },
  methods: {
    receiveMessage() {
      try {
        firebase.messaging().onMessage(payload => {
          const audio = new Audio(NotificationSound)
          audio.play()
          const customBody = this.$createElement(
            'h6',
            {
              on: {
                click: () => this.handleNotificationClick(payload),
              },
              class: 'm-0 text-primary font-weight-normal cursor-pointer',
            },
            payload.notification.body,
          )
          this.$bvToast.toast(customBody, {
            title: payload.notification.title,
            solid: true,
            autoHideDelay: 6000,
            variant: 'primary',
          })
          console.log('payload ', payload)
        })
      } catch (e) {
        console.log(e)
      }
    },
    handleNotificationClick(payload) {
      if (+payload.data.reference_db === this.$store.state.reference.selectedDB.id) {
        this.$router.push(this.getFcmRoute(payload.data))
      } else {
        this.$swal({
          title: 'Pindah Database',
          text: 'Notifikasi ini dari reference database lain, ingin pindah database?',
          showCancelButton: true,
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batalkan',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'text-danger btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('changeReferenceDB', { id: +payload.data.reference_db }).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengubah reference DB!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              }, {
                position: 'top-left',
              })
              this.$router.push(this.getFcmRoute(payload.data))
              setTimeout(() => {
                this.$router.go()
              }, 500)
            })
          }
        })
      }
    },
    getFcmRoute(data) {
      if (data.notification_category === '1') return `/quotations/${data.reference_id}/1`
      if (data.notification_category === '2') return '/tasks/unfinished'
      return null
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    const online = useOnline()

    const showToast = (title, variant, icon) => {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    watch(online, val => {
      if (!val) {
        showToast('Anda sedang offline!', 'danger', 'WifiOffIcon')
      } else {
        showToast('Kembali online!', 'success', 'WifiIcon')
      }
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
