import gql from 'graphql-tag'

const checkOAuth2Token = gql`
  query checkOAuth2Token {
    checkOAuth2Token {
      isValid
      email
    }
  }
`

export default checkOAuth2Token
