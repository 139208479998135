import gql from 'graphql-tag'

const performancePenjualan = gql`
  query(
    $filter: filterPerformancePenjualan!
  ){
    performancePenjualan (
      filter: $filter
    ) {
      accumulation_total
      accumulation_total_hpp
      accumulation_total_liter
      detail_performance {
        date
        total
        total_hpp
        total_liter
      }
      top_items {
        item_id
        item_id
        item_code
        total
        total_hpp
        total_liter
      }
      top_items_liter {
        item_id
        item_name
        item_code
        total
        total_hpp
        total_liter
      }
      top_customers {
        customer_id
        total
        total_hpp
        total_liter
      }
      top_customers_liter {
        customer_id
        total
        total_hpp
        total_liter
      }
    }
  }
`
export default performancePenjualan
