import gql from 'graphql-tag'

const deleteDryReport = gql`
  mutation deleteDryReport(
    $dry_report_id: Int!
  ){
    deleteDryReport(
      dry_report_id: $dry_report_id
    ){
      status
      message
    }
  }
`
export default deleteDryReport
