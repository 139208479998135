import gql from 'graphql-tag'

const restoreProject = gql`
  mutation restoreProject(
    $project_id: Int!
  ){
    restoreProject(
      project_id: $project_id
    ){
      status
      message
    }
  }

`
export default restoreProject
