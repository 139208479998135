import gql from 'graphql-tag'

const projectDetail = gql`
  query projectDetail(
    $project_id: Int!
  ){
    projectDetail(
      project_id: $project_id
    ){
      id
      name
      customer {
        id
        name
        customerNo
        mobilePhone
        workPhone
        npwpNo
        email
        billStreet
        billCity
        billProvince
        billCountry
        customerLimitAmountValue
        defaultSalesDisc
        saldoPiutang
        priceCategory {
          id
          name
        }
        category {
          id
          name
        }
        isAssigned
        term {
          id
          name
        }
      }
      category {
        id
        name
      }
      status
      tgl_reminder
      reference_db {
        id
        name
      }
      areaCategories {
        id
        name
      }
      surfaceAreas {
        area_category {
          id
          name
        }
        surface_area
      }
      isAssigned
    }
  }
`
export default projectDetail
