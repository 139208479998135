import gql from 'graphql-tag'

const dashboardPerformance = gql`
  query dashboardPerformance(
    $filter: filterDashboardPerformance!
  ){
    dashboardPerformance (filter: $filter) {
      customers {
        customer_id
        customerNo
        customer_name
        customer_email
        total_rupiah
        total_liter
      }
      items {
        item_id
        item_name
        item_code
        total_rupiah
        total_liter
      }
      users {
        user_id
        user_name
        user_phone
        total_rupiah
        total_liter
      }
    }
  }
`
export default dashboardPerformance
