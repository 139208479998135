/* eslint-disable import/no-cycle */
import Vue from 'vue'
import VueRouter from 'vue-router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/customer/Customer.vue'),
      meta: {
        pageTitle: 'Customers',
        breadcrumb: [
          {
            text: 'Customers',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/customers/:id',
      name: 'customer-detail',
      component: () => import('@/views/customer/CustomerDetail.vue'),
      props: true,
      meta: {
        pageTitle: 'Detail Customer',
        breadcrumb: [
          {
            text: 'Customers',
            active: false,
          },
          {
            text: 'Customer Detail',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/customers/:id/edit',
      name: 'customer-edit',
      component: () => import('@/views/customer/CustomerEdit.vue'),
      props: true,
      meta: {
        pageTitle: 'Edit Customer',
        requiresAuth: true,
      },
    },
    {
      path: '/addcustomer',
      name: 'customer-add',
      component: () => import('@/views/customer/CustomerAdd.vue'),
      meta: {
        pageTitle: 'Tambah Customer',
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
      },
      beforeEnter: (to, from, next) => {
        const hasUserData = localStorage.getItem('user')
        if (hasUserData) {
          next(from)
        } else {
          next()
        }
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/project/Projects.vue'),
      meta: {
        pageTitle: 'Projects',
        requiresAuth: true,
      },
    },
    {
      path: '/projects/:id',
      name: 'project-detail',
      component: () => import('@/views/project/ProjectDetail.vue'),
      meta: {
        pageTitle: 'Project Detail',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/projects/:id/edit',
      name: 'project-edit',
      component: () => import('@/views/project/ProjectEdit.vue'),
      meta: {
        pageTitle: 'Edit Project',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/addproject',
      name: 'addproject',
      component: () => import('@/views/project/ProjectAdd.vue'),
      meta: {
        pageTitle: 'Tambah Project',
        requiresAuth: true,
      },
      props: route => ({
        ...route.params,
      }),
    },
    {
      path: '/items',
      name: 'items',
      component: () => import('@/views/item/Items.vue'),
      meta: {
        pageTitle: 'Items',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/items/:id',
      name: 'item-detail',
      component: () => import('@/views/item/ItemsDetail.vue'),
      meta: {
        pageTitle: 'Detail Item',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/items/:id/edit',
      name: 'item-edit',
      component: () => import('@/views/item/ItemEdit.vue'),
      meta: {
        pageTitle: 'Edit Item',
        requiresAuth: true,
        needPermission: 'itemForm',
      },
      props: true,
    },
    {
      path: '/scheme',
      name: 'scheme',
      component: () => import('@/views/scheme/Scheme.vue'),
      meta: {
        pageTitle: 'Scheme',
        requiresAuth: true,
      },
    },
    {
      path: '/scheme/:id',
      name: 'scheme-detail',
      component: () => import('@/views/scheme/SchemeDetail.vue'),
      meta: {
        pageTitle: 'Detail Scheme',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/scheme/:id/edit',
      name: 'scheme-edit',
      component: () => import('@/views/scheme/SchemeAdd.vue'),
      meta: {
        pageTitle: 'Edit Scheme',
        requiresAuth: true,
        needPermission: 'schemeEdit',
      },
      props: true,
    },
    {
      path: '/scheme/:id/copy',
      name: 'scheme-copy',
      component: () => import('@/views/scheme/SchemeAdd.vue'),
      meta: {
        pageTitle: 'Copy Scheme',
        requiresAuth: true,
        needPermission: 'schemeAdd',
      },
      props: true,
    },
    {
      path: '/addscheme',
      name: 'addscheme',
      component: () => import('@/views/scheme/SchemeAdd.vue'),
      meta: {
        pageTitle: 'Tambah Scheme',
        requiresAuth: true,
        needPermission: 'schemeAdd',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
      beforeEnter: (to, from, next) => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Halaman yang kamu cari tidak ditemukan',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        next()
      },
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: () => import('@/views/error/ItemNotFound.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('@/views/error/Unauthorized.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/test/componenttest',
      name: 'componenttest',
      component: () => import('@/views/test/ComponentTest.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/test/chartjs',
      name: 'chartjs',
      component: () => import('@/views/test/StackedBarGroupChart.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/quotations',
      name: 'quotations',
      component: () => import('@/views/quotation/Quotations.vue'),
      meta: {
        pageTitle: 'Quote',
        requiresAuth: true,
      },
      props: route => ({
        ...route.params,
      }),
    },
    {
      path: '/quotations/:id/:version',
      name: 'quotation-detail',
      component: () => import('@/views/quotation/QuotationDetail.vue'),
      meta: {
        pageTitle: 'Detail Quote',
        requiresAuth: true,
      },
      props: route => ({
        ...route.params,
      }),
    },
    {
      path: '/quotations/:id/:version/revise',
      name: 'revisequote',
      component: () => import('@/views/quotation/QuotationAdd.vue'),
      meta: {
        pageTitle: 'Revisi Quote',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/quotations/:id/:version/edit',
      name: 'editquote',
      component: () => import('@/views/quotation/QuotationAdd.vue'),
      meta: {
        pageTitle: 'Edit Quote',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/addquote',
      name: 'addquote',
      component: () => import('@/views/quotation/QuotationAdd.vue'),
      meta: {
        pageTitle: 'Tambah Quote',
        requiresAuth: true,
      },
      props: route => ({
        ...route.params,
      }),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/user/User.vue'),
      meta: {
        pageTitle: 'Users',
        requiresAuth: true,
        needPermission: 'userPage',
      },
    },
    {
      path: '/user/:id',
      name: 'user-detail',
      component: () => import('@/views/user/UserDetail.vue'),
      meta: {
        pageTitle: 'Detail User',
        requiresAuth: true,
      },
      props: route => ({
        ...route.params,
      }),
    },
    {
      path: '/adduser',
      name: 'adduser',
      component: () => import('@/views/user/UserAdd.vue'),
      meta: {
        pageTitle: 'Tambah User',
        requiresAuth: true,
      },
    },
    {
      path: '/tasks/:filter',
      name: 'task-filter',
      component: () => import('@/views/task/Task.vue'),
      meta: {
        pageTitle: 'Tasks',
        requiresAuth: true,
        needPermission: 'taskPage',
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
      props: route => ({
        ...route.params,
      }),
    },
    {
      path: '/tasks/tag/:tag',
      name: 'task-tag',
      component: () => import('@/views/task/Task.vue'),
      meta: {
        pageTitle: 'Tasks',
        requiresAuth: true,
        needPermission: 'taskPage',
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/profile/setting',
      name: 'profilesetting',
      component: () => import('@/views/profile/ProfileSettings.vue'),
      meta: {
        pageTitle: 'Pengaturan',
        requiresAuth: true,
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/report/Reports.vue'),
      meta: {
        pageTitle: 'Reports',
        requiresAuth: true,
      },
    },
    {
      path: '/dryreport',
      name: 'dryreport',
      component: () => import('@/views/dryreport/DryReport.vue'),
      meta: {
        pageTitle: 'Dry Report',
        requiresAuth: true,
      },
    },
    {
      path: '/dryreport/:id/edit',
      name: 'edit-dryreport',
      component: () => import('@/views/dryreport/DryReportForm.vue'),
      meta: {
        pageTitle: 'Dry Report',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/adddryreport',
      name: 'adddryreport',
      component: () => import('@/views/dryreport/DryReportForm.vue'),
      meta: {
        pageTitle: 'Tambah Dry Report',
        requiresAuth: true,
      },
    },
    {
      path: '/salesorder',
      name: 'salesorder',
      component: () => import('@/views/salesorder/SalesOrder.vue'),
      meta: {
        pageTitle: 'Sales Order',
        requiresAuth: true,
      },
    },
    {
      path: '/salesorder/:id',
      name: 'salesorder-detail',
      component: () => import('@/views/salesorder/SalesOrderDetail.vue'),
      meta: {
        pageTitle: 'Sales Order',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/salesorder/:id/edit',
      name: 'salesorder-edit',
      component: () => import('@/views/salesorder/SalesOrderForm.vue'),
      meta: {
        pageTitle: 'Edit Sales Order',
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/addsalesorder',
      name: 'salesorder-add',
      component: () => import('@/views/salesorder/SalesOrderForm.vue'),
      meta: {
        pageTitle: 'Sales Order',
        requiresAuth: true,
      },
      props: route => ({
        ...route.params,
      }),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  window.document.title = to.meta && to.meta.pageTitle ? `${to.meta.pageTitle} | Quotation System` : 'Quotation System'
  const hasUserData = localStorage.getItem('user')
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !hasUserData) {
    store.commit('app/setNextRoute', to)
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Harap login terlebih dahulu',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
    next('/login')
  } else {
    await store.dispatch('checkExpiredToken')
    next()
  }
})

router.beforeResolve((to, from, next) => {
  const permission = router.app.$store.getters.getUserPermission
  const { needPermission } = to.meta
  if (needPermission) {
    if (!permission[needPermission]) {
      next('/unauthorized')
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kamu tidak punya akses ke halaman tersebut!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
