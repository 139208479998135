import gql from 'graphql-tag'

const performanceTargetSales = gql`
  query performanceTargetSales(
    $filter: filterPerformanceTargetSales!
  ){
    performanceTargetSales (
      filter: $filter
    ) {
      accumulation_total
      accumulation_total_liter
      target_rupiah
      target_liter
      percentage_total_to_target
      percentage_total_liter_to_target
      detail_performance {
        date
        total
        total_hpp
        total_liter
      }
    }
  }
`
export default performanceTargetSales
