import gql from 'graphql-tag'

const salesOrderType = gql`
  query salesOrderType{
    salesOrderType {
      id
      name
    }
  }
`
export default salesOrderType
