import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import errorModal from '@/store/error-handling'
import { createHelpers } from 'vuex-map-fields'
import { deleteCustomer, restoreCustomer } from '@/graphql/mutation'

const { getCustomersField, updateCustomersField } = createHelpers({
  getterType: 'getCustomersField',
  mutationType: 'updateCustomersField',
})

export default {
  state: {
    loadingCustomer: false,
    customerList: [],
    assignedCustomer: [],
    customerCount: 0,
    customerCategory: [],
    priceCategory: [],
    sortOptions: [
      {
        label: 'Nama Customer ↓',
        field: 'name',
        sortType: 'asc',
      },
      {
        label: 'Nama Customer ↑',
        field: 'name',
        sortType: 'desc',
      },
      {
        label: 'Kode Customer ↓',
        field: 'customerNo',
        sortType: 'asc',
      },
      {
        label: 'Kode Customer ↑',
        field: 'customerNo',
        sortType: 'desc',
      },
      {
        label: 'Limit Customer ↓',
        field: 'customerLimitAmountValue',
        sortType: 'asc',
      },
      {
        label: 'Limit Customer ↑',
        field: 'customerLimitAmountValue',
        sortType: 'desc',
      },
    ],
    customerFilter: {
      search: '',
      npwp: '',
      customerCategory: [],
      status: { id: 1, label: 'Aktif' },
      selectedSort: {
        label: 'Nama Customer ↓',
        field: 'name',
        sortType: 'asc',
      },
      offset: 1,
      limit: 25,
    },
  },
  getters: {
    getCustomersField,
    getLoadingCustomer: state => state.loadingCustomer,
    getCustomerList: state => state.customerList,
    getCategoryCustomer: state => state.customerCategory,
    getPriceCategoryCustomer: state => state.priceCategory,
    getCustomerAssigned: state => state.assignedCustomer,
  },
  mutations: {
    updateCustomersField,
    setLoadingCustomer(state, payload) {
      state.loadingCustomer = payload
    },
    setCustomerList(state, customers) {
      state.customerList = customers
    },
    setCustomerCount(state, count) {
      state.customerCount = count
    },
    setCustomerCategory(state, payload) {
      state.customerCategory = payload
    },
    setPriceCategory(state, payload) {
      state.priceCategory = payload
    },
    pushCustomer(state, payload) {
      state.customerList.push(payload)
      state.customerCount += 1
    },
    setCustomerAssigned(state, payload) {
      state.assignedCustomer = payload
    },
  },
  actions: {
    async getCustomers(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{customers(
              filter: ${payload.filter},
              pagination: ${payload.pagination},
            ){
              count
              customers {
                id
                customerNo
                name
                defaultSalesDisc
                mobilePhone
                workPhone
                npwpNo
                email
                street
                city
                province
                country
                balance
                status
                customerLimitAmountValue
                priceCategory
                category
                isInAccurate
                isAssigned
              }
              notes
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.customers)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async getAllCustomer(state) {
      if (!state.getters.getLoadingCustomer) {
        state.commit('setLoadingCustomer', true)
        await apolloClient.query({
          query: gql`
            query{customers{
              count
              customers {
                id
                name
                customerNo
                mobilePhone
                workPhone
                npwpNo
                email
                balance
                customerLimitAmountValue
                defaultSalesDisc
                status
                priceCategory
                category
                isInAccurate
                isAssigned
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.commit('setCustomerList', result.data.customers.customers)
          state.commit('setCustomerCount', result.data.customers.count)
          state.commit('setLoadingCustomer', false)
        }).catch(err => {
          errorModal(err)
          state.commit('setLoadingCustomer', false)
        })
      }
    },
    addCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $customerNo: String!
              $name: String!
              $mobilePhone: String
              $workPhone: String
              $npwpNo: String
              $email: String!
              $customerLimit: Float!
              $billStreet: String 
              $billCity: String
              $billProvince: String
              $billCountry: String
              $priceCategoryName: String!
              $categoryName: String
              $termName: String!
              $defaultSalesDisc: Float!
            ){addCustomerDB(params: {
              customerNo: $customerNo
              name: $name
              mobilePhone: $mobilePhone
              workPhone: $workPhone
              npwpNo: $npwpNo
              email: $email
              customerLimitAmountValue: $customerLimit
              billStreet: $billStreet
              billCity: $billCity
              billProvince: $billProvince
              billCountry: $billCountry
              priceCategoryName: $priceCategoryName
              categoryName: $categoryName
              termName: $termName
              defaultSalesDisc: $defaultSalesDisc
            }) {
              id
              status
              message
            }}
          `,
          variables: payload,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.addCustomerDB)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    addCustomerAccurate(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{
              addCustomerAccurate(customer_id: "${payload}") {
                id
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCustomerDetail(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{customerDetail(customer_id: "${payload}"){
              id
              name
              customerNo
              mobilePhone
              workPhone
              npwpNo
              email
              billStreet
              billCity
              billProvince
              billCountry
              customerLimitAmountValue
              defaultSalesDisc
              saldoPiutang
              status
              priceCategory {
                id
                name
              }
              category {
                id
                name
              }
              isAssigned
              term {
                id
                name
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.customerDetail)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getCustomerCategory(state) {
      apolloClient.query({
        query: gql`
          query {customerCategory{
            id
            name
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setCustomerCategory', result.data.customerCategory)
      }).catch(err => {
        errorModal(err)
      })
    },
    async getPriceCategory(state) {
      await apolloClient.query({
        query: gql`
          query {priceCategory{
            id
            name
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setPriceCategory', result.data.priceCategory)
      }).catch(err => {
        errorModal(err)
      })
    },
    getSalesAssigned(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query {
              salesAssigned (customer_id: "${payload.id}") {
                id
                name
                phone
                photo
                role {
                  id
                  name
                }
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.salesAssigned)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getDailyReport(state, payload) {
      return new Promise((resolve, reject) => {
        const customerId = payload.customerid ? `"${payload.customerid}"` : null
        const userId = payload.userid ? +payload.userid : null

        apolloClient.query({
          query: gql`
            query{dailyReport (
              customer_id: ${customerId}
              user_id: ${userId}
              filter: ${payload.filter ? payload.filter : null}
              pagination: ${payload.pagination ? payload.pagination : null}
            ){
              id
              name
              type
              date
              project {
                id
                category {
                  id
                  name
                }
                customer_id
                name
                tgl_reminder
                status
              }
              userCreate {
                id
                phone
                email
                name
                role {
                  id
                  name
                }
                photo
                status
                target_rupiah
                target_liter
                reference_accurate_id_1
                reference_accurate_id_2
              }
              status {
                id
                name
              }
              info
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.dailyReport)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getPerformancePenjualanCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{performancePenjualanCustomer (customer_id: "${payload.customer_id}", filter: {
              user_id: ${payload.user_id ? payload.user_id : null}
              date: ${payload.date ? payload.date : null}
              data_source: ${payload.source ? payload.source : null}
            }){
              accumulation_total
              accumulation_total_hpp
              accumulation_total_liter
              detail_performance{
                month
                total
                total_hpp
                total_liter
              }
              top_items{
                item_id
                item_name
                item_code
                total
                total_hpp
                total_liter
              }
              top_items_liter{
                item_id
                item_name
                item_code
                total
                total_hpp
                total_liter
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.performancePenjualanCustomer)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    checkCustomerNo(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{checkCustomerNo(customerNo: "${payload}")}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.checkCustomerNo)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getPerformanceQuotesCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query {
              performanceQuotesCustomer (
                customer_id: "${payload.customer_id}"
                period: "${payload.period}"
              ) {
                period
                value
                quote
                type
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.performanceQuotesCustomer)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    removeCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteCustomer,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            customer_id: payload,
          },
        }).then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    restoreCustomerFunc(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: restoreCustomer,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            customer_id: payload,
          },
        }).then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
