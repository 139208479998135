import { apolloClient } from '@/vue-apollo'
import { createHelpers } from 'vuex-map-fields'
import { ralColors, scheme, schemeDetail } from '@/graphql/query'
import {
  addScheme, deleteScheme, restoreScheme, updateScheme,
} from '@/graphql/mutation'
import errorModal from '../error-handling'

const { getSchemeField, updateSchemeField } = createHelpers({
  getterType: 'getSchemeField',
  mutationType: 'updateSchemeField',
})

export default {
  state: {
    loadingScheme: true,
    schemeList: [],
    schemeCount: 0,
    ralColors: [],
    filterScheme: {
      search: '',
      status: { name: 'Aktif', value: 1 },
      perPage: 25,
      offset: 1,
    },
  },
  getters: {
    getSchemeField,
    getLoadingScheme: state => state.loadingScheme,
    getSchemeList: state => state.schemeList,
    getSchemeCount: state => state.schemeCount,
    getRalColors: state => state.ralColors,
  },
  mutations: {
    updateSchemeField,
    setSchemeList(state, payload) {
      state.schemeList = payload
    },
    setSchemeCount(state, payload) {
      state.schemeCount = payload
    },
    setRalColors(state, payload) {
      state.ralColors = payload
    },
  },
  actions: {
    async getScheme(state) {
      state.commit('updateSchemeField', { path: 'loadingScheme', value: true })
      await apolloClient.query({
        query: scheme,
        variables: {
          pagination: state.pagination ? state.pagination : null,
          filter: state.filter ? state.filter : null,
        },
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('updateSchemeField', { path: 'loadingScheme', value: false })
        state.commit('setSchemeList', result.data.scheme.schemes)
        state.commit('setSchemeCount', result.data.scheme.count)
      }).catch(err => {
        errorModal(err)
        state.commit('updateSchemeField', { path: 'loadingScheme', value: false })
      })
    },
    getColors(state) {
      apolloClient.query({
        query: ralColors,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setRalColors', result.data.ralColors)
      }).catch(err => {
        errorModal(err)
      })
    },
    getSchemeDetail(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: schemeDetail,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            scheme_id: payload,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.schemeDetail)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    addScheme(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: addScheme,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            params: {
              name: payload.name,
              notes: payload.notes,
              items: payload.items,
              color_id_ral: payload.color_id_ral,
            },
          },
        }).then(response => {
          resolve(response.data.addScheme)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    updateScheme(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: updateScheme,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            scheme_id: payload.id,
            params: {
              name: payload.name,
              notes: payload.notes,
              items: payload.items,
              color_id_ral: payload.color_id_ral,
            },
          },
        }).then(response => {
          resolve(response.data.updateScheme)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    deleteScheme(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteScheme,
          variables: {
            scheme_id: +payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(response => {
          resolve(response.data.deleteScheme)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    restoreScheme(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: restoreScheme,
          variables: {
            scheme_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(response => {
          resolve(response.data.restoreScheme)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
  },
}
