import gql from 'graphql-tag'

const restoreCustomer = gql`
  mutation restoreCustomer(
    $customer_id: String!
  ){
    restoreCustomer(
      customer_id: $customer_id
    ){
      status
      message
    }
  }
`
export default restoreCustomer
