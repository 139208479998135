import gql from 'graphql-tag'

const dashboardPendingQuotes = gql`
  query dashboardPendingQuotes {
    dashboardPendingQuotes {
      id
      name
      last_version
      project {
        id
        name
        category {
          id
          name
        }
        tgl_reminder
        status
      }
      status {
        id
        name
      }
      total
      total_hpp
      created_at
      userCreate {
        id
        name
        role {
          id
          name
        }
        photo
      }
    }
  }
`
export default dashboardPendingQuotes
