import gql from 'graphql-tag'

const signOutOAuth2Token = gql`
  mutation signOutOAuth2Token {
    signOutOAuth2Token{
      status 
      message
    }
  }
`
export default signOutOAuth2Token
