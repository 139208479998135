import gql from 'graphql-tag'

const salesOrder = gql`
  query salesOrder(
    $filter: filterSalesOrder
    $pagination: Pagination
  ){
    salesOrder(
      filter: $filter
      pagination: $pagination
    ){
      count
      sales_order {
        id
        transDate
        shipDate
        shipment {
          id
          name
        }
        tax1Amount
        poNumber
        number
        description
        userCreate {
          id
          name
          id
          phone
          email
          name
          role {
            id
            name
          }
          photo
          status
        }
        status
        customer {
          id
          name
        }
        subTotal
        cashDiscount
        totalAmount
        toAddress
        paymentTerm {
          id
          name
        }
      }
    }
  }
`
export default salesOrder
