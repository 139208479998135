import gql from 'graphql-tag'

const users = gql`
  query users(
    $filter: filterUser
  ){
    users(
      filter: $filter
    ){
      count
      users {
        id
        phone
        email
        name
        photo
        role {
          id
          name
        }
        ttd
        status
        target_rupiah
        target_liter
        target_rupiah_2
        target_liter_2
        reference_accurate_id_1
        reference_accurate_id_2
        reference_db {
          id
          name
        }
      }
    }
  }
`
export default users
