import gql from 'graphql-tag'

const dryReports = gql`
  query dryReports(
    $filter: filterDryReport
    $pagination: Pagination
  ){
    dryReports(
      filter: $filter,
      pagination: $pagination,
    ){
      count
      dry_reports {
        id
        dft_efficiency
        vol_efficiency
        file
        event_start
        event_end
        location {
          id
          name
          city {
            id
            name
          }
          loss_bottom
          loss_topside
        }
        created_at
        userCreate {
          id
          phone
          email
          name
          photo
          role {
            id
            name
          }
        }
      }
    }
  }
`
export default dryReports
