import { createHelpers } from 'vuex-map-fields'
import { apolloClient } from '@/vue-apollo'
import errorModal from '@/store/error-handling'
import {
  salesOrder, salesOrderDetail, shipment, salesOrderType, salesOrderCategory,
} from '@/graphql/query'
import {
  addSalesOrder, deleteSalesOrder, deleteSalesOrderAttachment, salesOrderAttachment, updateSalesOrder,
} from '@/graphql/mutation'

const { getSalesOrderField, updateSalesOrderField } = createHelpers({
  getterType: 'getSalesOrderField',
  mutationType: 'updateSalesOrderField',
})

export default {
  state: {
    loadingSalesOrder: true,
    salesOrderList: [],
    salesOrderCount: 0,
    shipmentOptions: [],
    salesOrderFilter: {
      search: '',
      selectedSort: {
        label: 'Tanggal Transaksi ↑',
        field: 'transDate',
        sortType: 'desc',
      },
      offset: 1,
      limit: 25,
      customer: '',
      status: '',
      tanggal_transaksi: {},
    },
    salesOrderSortOptions: [
      {
        label: 'Tanggal Transaksi ↓',
        field: 'transDate',
        sortType: 'asc',
      },
      {
        label: 'Tanggal Transaksi ↑',
        field: 'transDate',
        sortType: 'desc',
      },
      {
        label: 'Nomor ↓',
        field: 'poNumber',
        sortType: 'asc',
      },
      {
        label: 'Nomor ↑',
        field: 'poNumber',
        sortType: 'desc',
      },
      {
        label: 'Total ↓',
        field: 'subTotal',
        sortType: 'asc',
      },
      {
        label: 'Total ↑',
        field: 'subTotal',
        sortType: 'desc',
      },
    ],
    statusOptions: [
      {
        label: 'CLOSED',
        variant: 'light-secondary',
      },
      {
        label: 'DRAFT',
        variant: 'light-info',
      },
      {
        label: 'PROCEED',
        variant: 'dark',
      },
      {
        label: 'QUEUE',
        variant: 'light-success',
      },
      {
        label: 'REJECTED',
        variant: 'danger',
      },
      {
        label: 'UNAPPROVED',
        variant: 'light-warning',
      },
      {
        label: 'WAITING',
        variant: 'secondary',
      },
    ],
    salesOrderTypes: [],
    salesOrderCategories: [],
  },

  getters: {
    getSalesOrderField,
    getSalesOrderStatusOptions: state => state.statusOptions,
    getShipmentOptions: state => state.shipmentOptions,
    getSalesOrderTypes: state => state.salesOrderTypes,
    getSalesOrderCategories: state => state.salesOrderCategories,
  },
  mutations: {
    updateSalesOrderField,
  },
  actions: {
    getSalesOrder(state, payload) {
      if (payload && !payload.promise) {
        state.commit('updateSalesOrderField', { path: 'loadingSalesOrder', value: true })
      }
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: salesOrder,
          variables: {
            filter: payload.filter,
            pagination: payload.pagination,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          if (payload && payload.promise) {
            resolve(result)
          } else {
            state.commit('updateSalesOrderField', { path: 'salesOrderCount', value: result.data.salesOrder.count })
            state.commit('updateSalesOrderField', { path: 'salesOrderList', value: result.data.salesOrder.sales_order })
            state.commit('updateSalesOrderField', { path: 'loadingSalesOrder', value: false })
          }
        }).catch(err => {
          state.commit('updateSalesOrderField', { path: 'loadingSalesOrder', value: false })
          reject(err)
          errorModal(err)
        })
      })
    },
    addSalesOrder(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: addSalesOrder,
          variables: { params: payload },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    salesOrderAttachment(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: salesOrderAttachment,
          variables: {
            sales_order_id: payload.sales_order_id,
            files: payload.files,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
            fetchOptions: {
              useUpload: true,
              onProgress: ev => {
                payload.files.forEach(file => {
                  if (file.name) {
                    state.commit('uploadProgress', {
                      name: file.name,
                      progress: Math.round((ev.loaded / ev.total) * 100),
                      error: false,
                    })
                  }
                })
              },
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          state.commit('uploadProgress', {
            name: payload.file.name,
            progress: 0,
            error: true,
          })
          errorModal('Upload file gagal, harap upload kembali!')
          reject(err)
        })
      })
    },
    updateSalesOrder(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: updateSalesOrder,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            sales_order_id: payload.id,
            params: payload,
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    deleteSalesOrder(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteSalesOrder,
          variables: {
            sales_order_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getSalesOrderDetail(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: salesOrderDetail,
          variables: {
            sales_order_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.salesOrderDetail)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    deleteSalesOrderAttachment(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteSalesOrderAttachment,
          variables: {
            attachment_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result.data.deleteSalesOrderAttachment)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getShipments(state) {
      if (!state.state.shipmentOptions.length) {
        apolloClient.query({
          query: shipment,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.commit('updateSalesOrderField', { path: 'shipmentOptions', value: result.data.shipment })
        }).catch(err => {
          errorModal(err)
        })
      }
    },
    getSalesOrderTypes(state) {
      if (!state.state.salesOrderTypes.length) {
        apolloClient.query({
          query: salesOrderType,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.commit('updateSalesOrderField', { path: 'salesOrderTypes', value: result.data.salesOrderType })
        }).catch(err => {
          errorModal(err)
        })
      }
    },
    getSalesOrderCategories(state) {
      if (!state.state.salesOrderCategories.length) {
        apolloClient.query({
          query: salesOrderCategory,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.commit('updateSalesOrderField', { path: 'salesOrderCategories', value: result.data.salesOrderCategory })
        }).catch(err => {
          errorModal(err)
        })
      }
    },
  },
}
