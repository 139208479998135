import gql from 'graphql-tag'

const projectCategory = gql`
  query projectCategory{
    projectCategory{
      id
      name
    }
  }
`
export default projectCategory
