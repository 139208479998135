import gql from 'graphql-tag'

const roles = gql`
  query roles{
    roles{
      id
      name
    }
  }
`
export default roles
