import gql from 'graphql-tag'

const removeDataSheet = gql`
  mutation removeDataSheet(
    $item_id: Int!
  ){
    removeDataSheet(
      item_id: $item_id
    ){
      status
      message
    }
  }
`
export default removeDataSheet
