import { apolloClient } from '@/vue-apollo'
import { tags, tasks } from '@/graphql/query'
import {
  addTask, deleteTask, doneUndoneTask, updateTask,
} from '@/graphql/mutation'
import errorModal from '../error-handling'

export default {
  state: {
    taskList: [],
    unfinishedTask: 0,
    tags: [],
    loadingTask: true,
  },
  getters: {
    getTaskList: state => state.taskList,
    getUnfinishedTask: state => state.unfinishedTask,
    getTagsList: state => state.tags,
    getLoadingTask: state => state.loadingTask,
  },
  mutations: {
    setTaskList: (state, payload) => {
      state.taskList = payload
    },
    setUnfinishedTask: (state, payload) => {
      state.unfinishedTask = payload
    },
    setTagsList: (state, payload) => {
      state.tags = payload
    },
    setLoadingTask: (state, payload) => {
      state.loadingTask = payload
    },
  },
  actions: {
    getTask(state) {
      state.commit('setLoadingTask', true)
      apolloClient.query({
        query: tasks,
        variables: {
          filter: {
            sort: 'due_date',
            sortType: 'desc',
            user_id: state.rootGetters.getCurrentRole === 2 ? null : state.rootGetters.getCurrentUser.user.id,
          },
        },
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setLoadingTask', false)
        state.commit('setTaskList', result.data.tasks)
        state.commit('setUnfinishedTask', result.data.tasks.filter(el => el.status === 0).length)
      }).catch(err => {
        errorModal(err)
      })
    },
    addTask(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: addTask,
          variables: {
            user_id: payload.user_id,
            params: {
              description: payload.description,
              project_id: payload.project_id,
              due_date: payload.due_date,
              recurring: payload.recurring,
              tags: payload.tags,
            },
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(async response => {
          state.dispatch('getTask')
          resolve(response)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    updateTask(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: updateTask,
          variables: {
            task_id: payload.id,
            params: {
              description: payload.description,
              project_id: payload.project_id,
              due_date: payload.due_date,
              recurring: payload.recurring,
              tags: payload.tags,
            },
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(async response => {
          state.dispatch('getTask')
          resolve(response)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    doneTask(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: doneUndoneTask,
          variables: {
            task_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(async response => {
          state.dispatch('getTask')
          resolve(response)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    deleteTask(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteTask,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            task_id: payload,
          },
        }).then(async response => {
          state.dispatch('getTask')
          resolve(response)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getTags(state) {
      apolloClient.query({
        query: tags,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setTagsList', result.data.tags)
      }).catch(err => {
        errorModal(err)
      })
    },
  },
}
