import gql from 'graphql-tag'

const tasksPerformanceChart = gql`
  query tasksPerformanceChart(
    $user_id: Int!
  ){
    tasksPerformanceChart(
      user_id: $user_id
    ){
      month
      task_all
      task_done
      task_late
      task_not_done
    }
  }
`
export default tasksPerformanceChart
