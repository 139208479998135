import gql from 'graphql-tag'

const deleteSalesOrder = gql`
  mutation deleteSalesOrder(
    $sales_order_id: Int!
  ){
    deleteSalesOrder (sales_order_id: $sales_order_id) {
      status
      message
    }
  }
`
export default deleteSalesOrder
