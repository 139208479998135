import gql from 'graphql-tag'

const tags = gql`
  query tags{
    tags{
      id
      name
    }
  }
`
export default tags
