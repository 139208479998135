import gql from 'graphql-tag'

const quotes = gql`
    query quotes(
      $filter: filterQuotes
      $pagination: Pagination
    ){
      quotes(
        filter: $filter
        pagination: $pagination
      ){
        count
        quotes {
          id
          name
          project {
            id
            name
            tgl_reminder
            status
            category {
              id
              name
            }
          }
          status {
            id
            name
          }
          total
          total_hpp
          closed_at
          created_at
          updated_at
          userCreate {
            id
            name
            id
            phone
            email
            name
            role {
              id
              name
            }
            photo
            status
          }
          version
          reference_db {
            id
            name
          }
        }
      }
    }
`
export default quotes
