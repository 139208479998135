import gql from 'graphql-tag'

const addOAuth2Token = gql`
  mutation addOAuth2Token(
    $user_id: Int!
    $authCode: String!
    $email: String!
  ){
    addOAuth2Token(
      user_id: $user_id
      authCode: $authCode
      email: $email
    ){
      status
      message
    }
  }
`
export default addOAuth2Token
