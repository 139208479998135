export default {
// 1: sales, 2: manager, 3: sales admin, 4: technical
  access: {
    // page access
    homePage: [1, 2, 3, 4],
    customerPage: [1, 2, 3, 4],
    projectPage: [1, 2, 3, 4],
    projectAdd: [2],
    projectEdit: [2],
    projectDelete: [2],
    itemPage: [1, 2, 3, 4],
    itemAdd: [2, 3],
    itemEdit: [2, 3],
    schemePage: [1, 2, 3, 4],
    quotePage: [1, 2, 3, 4],
    userPage: [2],
    userDetailPage: [1, 2, 3, 4],
    taskPage: [1, 2, 3, 4],
    reportPage: [1, 2, 3, 4],
    salesOrderPage: [1, 2, 3, 4],

    // quotes action permission
    quotationAdd: [1, 2, 3, 4],
    quotationEdit: [1, 2, 3, 4],
    quotationDelete: [1, 2, 3, 4],

    // item action permission
    itemForm: [2, 3],

    // scheme action permission
    schemeAdd: [2, 3],
    schemeEdit: [2, 3],
    schemeDelete: [2, 3],

    // general stuff
    viewHpp: [2],
  },
}
