import gql from 'graphql-tag'

const updateTask = gql`
  mutation updateTask(
    $task_id: Int!
    $params: paramTask
  ){
    updateTask (
      task_id: $task_id
      params: $params
    ){
      status
      message
    }
  }
`
export default updateTask
