import gql from 'graphql-tag'

const updateFCMToken = gql`
  mutation updateFCMToken(
    $device_id: String!
    $token: String!
  ){
    updateFCMToken (
      device_id: $device_id
      token: $token
    ) {
      status
      message
    }
  }
`
export default updateFCMToken
