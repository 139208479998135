import gql from 'graphql-tag'

const dryReportDetail = gql`
  query dryReportDetail(
    $dry_report_id: Int!
  ){
    dryReportDetail (
      dry_report_id: $dry_report_id
    ){
      id
      dft_efficiency
      vol_efficiency
      file
      event_start
      event_end
      location{
        id
        name
        city {
          id
          name
        }
        loss_bottom
        loss_topside
      }
      created_at
      userCreate{
        id
        phone
        email
        name
        role {
          id
          name
        }
        photo
        ttd
      }
      areas{
        category {
          id
          name
        }
        loss
        quote_data
        real_data
        items {
          item_id
          item_name
          item_code
          item_type {
            id
            name
          }
          color {
            id_ral
            hex_code
            ind_name
            eng_name
          }
          liter
        }
      }
      items {
        item_id
        item_name
        item_code
        item_type {
          id
          name
        }
        data_sheet
        color {
          id_ral
          hex_code
          ind_name
          eng_name
        }
        quote_data
        real_data
      }
      quotes {
        id
        name
        total
        total_hpp
        total_volume
        last_version
        project {
          id
          category {
            id
            name
          }
          customer_id
          name
          tgl_reminder
          status
        }
      }
      created_at
    }
  }
`
export default dryReportDetail
