import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import { createHelpers } from 'vuex-map-fields'
import errorModal from '../error-handling'

const { getQuotesField, updateQuotesField } = createHelpers({
  getterType: 'getQuotesField',
  mutationType: 'updateQuotesField',
})

export default {
  state: {
    loadingQuotes: true,
    quotesList: [],
    quotesCount: 0,
    quotesBadge: {},
    statusOptions: {
      1: {
        label: 'Draft',
        badge: 'light-info',
        variant: 'info',
      },
      2: {
        label: 'Submitted',
        badge: 'light-warning',
        variant: 'warning',
      },
      3: {
        label: 'Approved',
        badge: 'light-success',
        variant: 'success',
      },
      4: {
        label: 'Rejected By Manager',
        badge: 'light-danger',
        variant: 'danger',
      },
      5: {
        label: 'Rejected By Customer',
        badge: 'danger',
        variant: 'danger',
      },
      6: {
        label: 'Closed',
        badge: 'light-secondary',
        variant: 'secondary',
      },
      7: {
        label: 'Forward to SO',
        badge: 'dark',
        variant: 'dark',
      },
    },
    sortOptions: [
      {
        label: 'Nama Quote ↓',
        field: 'name',
        sortType: 'asc',
      },
      {
        label: 'Nama Quote ↑',
        field: 'name',
        sortType: 'desc',
      },
      {
        label: 'Waktu Dibuat ↓',
        field: 'created_at',
        sortType: 'asc',
      },
      {
        label: 'Waktu Dibuat ↑',
        field: 'created_at',
        sortType: 'desc',
      },
      {
        label: 'Waktu Update ↓',
        field: 'updated_at',
        sortType: 'asc',
      },
      {
        label: 'Waktu Update ↑',
        field: 'updated_at',
        sortType: 'desc',
      },
      {
        label: 'Berlaku Sampai ↓',
        field: 'valid_until',
        sortType: 'asc',
      },
      {
        label: 'Berlaku Sampai ↑',
        field: 'valid_until',
        sortType: 'desc',
      },
      {
        label: 'Total Quote ↓',
        field: 'total',
        sortType: 'asc',
      },
      {
        label: 'Total Quote ↑',
        field: 'total',
        sortType: 'desc',
      },
    ],
    selectedCustomer: '',
    selectedProject: '',
    filterQuotes: {
      customer_id: [],
      created_at: {},
      status: [],
      search: '',
      userCreate: [],
      total: {
        min: '',
        max: '',
      },
      project_id: [],
      offset: 1,
      limit: 25,
      selectedSort: {
        label: 'Waktu Update ↑',
        field: 'updated_at',
        sortType: 'desc',
      },
    },
  },
  getters: {
    getQuotesField,
    getLoadingQuotes: state => state.loadingQuotes,
    getQuotesList: state => state.quotesList,
    getQuotesCount: state => state.quotesCount,
    getQuotesBadge: state => state.quotesBadge,
    getStatusOptions: state => state.statusOptions,
    getSelectedCustomer: state => state.selectedCustomer,
    getSelectedProject: state => state.selectedProject,
    getSortOptionsQuote: (state, store) => {
      if (store.getUserPermission.viewHpp) {
        return [
          ...state.sortOptions,
          {
            label: 'Margin Persentase ↓',
            field: 'margin_persentase',
            sortType: 'asc',
          },
          {
            label: 'Margin Persentase ↑',
            field: 'margin_persentase',
            sortType: 'desc',
          },
          {
            label: 'Total HPP ↓',
            field: 'total_hpp',
            sortType: 'asc',
          },
          {
            label: 'Total HPP ↑',
            field: 'total_hpp',
            sortType: 'desc',
          },
        ]
      }

      return state.sortOptions
    },
  },
  mutations: {
    updateQuotesField,
    setLoadingQuotes(state, payload) {
      state.loadingQuotes = payload
    },
    setQuotesList(state, payload) {
      state.quotesList = payload
    },
    setQuotesCount(state, payload) {
      state.quotesCount = payload
    },
    setQuotesBadge(state, payload) {
      state.quotesBadge = payload
    },
    setSelectedCustomer(state, payload) {
      state.selectedCustomer = payload
    },
    setSelectedProject(state, payload) {
      state.selectedProject = payload
    },
  },
  actions: {
    async getQuotes(state, payload) {
      if (!payload.promise) {
        state.commit('setLoadingQuotes', true)
      }

      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{quotes(
              filter: ${payload.filter},
              pagination: ${payload.pagination},
            ){
              count
              quotes {
                id
                name
                project {
                  id
                  name
                  tgl_reminder
                  status
                  category {
                    id
                    name
                  }
                }
                status {
                  id
                  name
                }
                total
                total_hpp
                closed_at
                created_at
                updated_at
                userCreate {
                  id
                  name
                  id
                  phone
                  email
                  name
                  role {
                    id
                    name
                  }
                  photo
                  status
                }
                version
                reference_db {
                  id
                  name
                }
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          if (payload.promise) {
            resolve(result.data.quotes.quotes)
          } else {
            state.commit('setLoadingQuotes', false)
            state.commit('setQuotesCount', result.data.quotes.count)
            state.commit('setQuotesList', result.data.quotes.quotes)
          }
          state.dispatch('getQuoteBadge')
        }).catch(err => {
          if (payload.promise) {
            reject(err)
          } else {
            state.commit('setLoadingQuotes', false)
          }
          errorModal(err)
        })
      })
    },
    getQuoteDetail(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{
              quoteDetail(quote_id: ${Number(payload.quote_id)}, version: ${Number(payload.version)}) {
                id
                name
                discount
                status {
                  id
                  name
                }
                total
                total_hpp
                notes
                created_at
                valid_until
                userCreate {
                  id
                  phone
                  email
                  name
                  role {
                    id
                    name
                  }
                  photo
                }
                project {
                  id
                  category {
                    id
                    name
                  }
                  customer {
                    id
                    name
                    customerNo
                    mobilePhone
                    workPhone
                    email
                    billStreet
                    saldoPiutang
                    customerLimitAmountValue
                    priceCategory {
                      id
                      name
                    }
                    category {
                      id
                      name
                    }
                    term {
                      id
                      name
                    }
                  }
                  name
                  tgl_reminder
                  status
                }
                last_version
                sales_order_id
                sales_order_number
                sales_order_transDate
                sales_order_status
                dry_report_id
                areaItems{
                  category {
                    id
                    name
                  }
                  surface_area
                  total
                  total_hpp
                  surface_preparation
                  notes
                  items {
                    item_id
                    item_name
                    item_code
                    price
                    hpp
                    subtotal
                    subtotal_hpp
                    liter
                    liter_detail {
                      item
                      agent_item
                      agent_item_2
                    }
                    coat
                    dry_film_thickness
                    theoritical
                    practical
                    loss
                    item_type {
                      id
                      name
                    }
                    data_sheet
                    vs_volume_solid
                    recommended_thinner_id
                    ratio_recommended_thinner_id
                    color {
                      id_ral
                      hex_code
                      ind_name
                      eng_name
                    }
                    lapis_uid
                    packaging {
                      id
                      name
                      liter
                    }
                    agent_item {
                      id
                      name
                      no
                      packaging {
                        id
                        name
                        liter
                      }
                    }
                    ratio_agent
                    agent_item_2 {
                      id
                      name
                      no
                      packaging {
                        id
                        name
                        liter
                      }
                    }
                    ratio_agent_2
                    thinner_code
                  }
                }
                quoteLogs {
                  created_at
                  status {
                    id
                    name
                  }
                  notes
                  user {
                    id
                    phone
                    email
                    name
                    photo
                    role {
                      id
                      name
                    }
                  }
                }
                reference_db {
                  id
                  name
                }
                isAssigned
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.quoteDetail)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getQuoteDetailSO(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query {
              quoteDetailSalesOrder(quote_id: ${payload}){
                project {
                  id
                  category {
                    id
                    name
                  }
                  name
                  tgl_reminder
                  status
                  customer {
                    id
                    name
                    customerNo
                  }
                }
                items {
                  item_name
                  item_code
                  total_liter
                  item_liter
                  packagings {
                    item_id
                    item_code
                    packaging_name
                    liter
                    stock
                  }
                  price
                  subtotal
                  agent_item {
                    item_name
                    item_liter
                    packagings {
                      item_id
                      item_code
                      packaging_name
                      liter
                      stock
                    }
                  }
                  agent_item_2 {
                    item_name
                    item_liter
                    packagings {
                      item_id
                      item_code
                      packaging_name
                      liter
                      stock
                    }
                  }
                }
                free_items {
                  item_name
                  total_liter
                  item_liter
                  packagings {
                    item_id
                    item_code
                    packaging_name
                    liter
                    stock
                  }
                  price
                  subtotal
                  agent_item {
                    item_name
                    item_liter
                    packagings {
                      item_id
                      item_code
                      packaging_name
                      liter
                      stock
                    }
                  }
                  agent_item_2 {
                    item_name
                    item_liter
                    packagings {
                      item_id
                      item_code
                      packaging_name
                      liter
                      stock
                    }
                  }
                }
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.quoteDetailSalesOrder)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getPaymentTerm(state) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{
              paymentTerm{
                id
                name
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.paymentTerm)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    addQuote(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $project_id: Int!
              $is_draft: Boolean
              $valid_until: DateTime!
              $notes: String
              $discount: Float!
              $total_volume: Float!
              $total: Float!
              $total_hpp: Float!
              $areas: [paramQuoteArea]!
            ){addQuote(
              project_id: $project_id
              is_draft: $is_draft
              params: {
                valid_until: $valid_until
                notes: $notes
                discount: $discount
                total_volume: $total_volume
                total: $total
                total_hpp: $total_hpp
                areas: $areas
              }
            ){
              id
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            project_id: payload.project_id,
            is_draft: payload.is_draft,
            valid_until: payload.valid_until,
            notes: payload.notes,
            discount: payload.discount,
            total_volume: payload.total_volume,
            total: payload.total,
            total_hpp: payload.total_hpp,
            areas: payload.areas,
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
    reviseQuote(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $quote_id: Int!
              $is_draft: Boolean
              $params: paramQuote!
            ){addNewVersionQuote(
              quote_id: $quote_id
              is_draft: $is_draft
              params: $params
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            quote_id: payload.quote_id,
            is_draft: payload.is_draft,
            params: payload.params,
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    submitQuote(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{submitQuote(
              quote_id: ${payload.id}
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    closeQuote(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{closeQuote(
              quote_id: ${payload.id}
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    acceptRejectQuote(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{acceptRejectQuote(
              quote_id: ${payload.id}
              status: ${payload.status}
              notes: "${payload.notes}"
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    rejectQuoteByCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{rejectQuoteByCustomer(
              quote_id: ${payload.id}
              notes: "${payload.notes}"
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    forwardQuote(state, payload) {
      return new Promise((resolve, reject) => {
        const variables = {
          customerNo: payload.customerNo,
          transDate: payload.transDate,
          cashDiscount: payload.cashDiscount,
          paymentTermName: payload.paymentTermName,
          poNumber: payload.poNumber,
          shipmentName: payload.shipmentName,
          toAddress: payload.toAddress,
          items: payload.items,
          type: payload.type.name,
          category: payload.category.name,
          description: payload.description,
        }

        if (payload.shipDate) variables.shipDate = payload.shipDate

        apolloClient.mutate({
          mutation: gql`
            mutation(
              $customerNo: String!
              $transDate: Date!
              $description: String
              $cashDiscount: Float
              $paymentTermName: String!
              $poNumber: String
              $shipDate: Date
              $shipmentName: String
              $toAddress: String
              $type: String!
              $category: String!
              $items: [itemsForward]!
            ){
              forwardQuote(
                quote_id: ${payload.quote_id}
                params: {
                  customerNo: $customerNo
                  transDate: $transDate
                  description: $description
                  cashDiscount: $cashDiscount
                  paymentTermName: $paymentTermName
                  poNumber: $poNumber
                  shipDate: $shipDate
                  shipmentName: $shipmentName
                  toAddress: $toAddress
                  type: $type
                  category: $category
                  items: $items
                }
              ){
                status
                message
              }
            }
          `,
          variables,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getQuotesCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{quotes(
              filter: {
                customer_id: "${payload.id}"
                sort: "updated_at"
                sortType: "desc"
              }
            ){
              count
              quotes {
                id
                name
                project {
                  id
                  name
                  tgl_reminder
                  status
                  category {
                    id
                    name
                  }
                }
                status {
                  id
                  name
                }
                total
                total_hpp
                closed_at
                created_at
                updated_at
                userCreate {
                  id
                  name
                  id
                  phone
                  email
                  name
                  role {
                    id
                    name
                  }
                  photo
                  status
                }
                version
                reference_db {
                  id
                  name
                }
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.quotes.quotes)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getQuoteBadge(state) {
      apolloClient.query({
        query: gql`
          query{quoteBadge {
            quote_submitted
            quote_action
            all_quote_action
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setQuotesBadge', result.data.quoteBadge)
      }).catch(err => {
        errorModal(err)
      })
    },
    updateQuote(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $quote_id: Int!
              $params: paramQuote!
            ){updateQuote(
              quote_id: $quote_id
              params: $params
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            quote_id: payload.quote_id,
            params: payload.params,
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    sendEmailCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        // const result = await state.dispatch('checkToken')
        // if (!result) {

        // }
        state.commit('newProcess', {
          id: payload.quote_id,
          name: `Mengirim Email Quote ${payload.quote_id} ke ${payload.to}`,
          status: 'loading',
        })
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $quote_id: Int!
              $type: Int!
              $to: [String!]!
              $cc: [String]
              $bcc: [String]
              $subject: String!
              $item_id: [Int]
              $isLossFactorVisible: Boolean
            ){
              sendEmailCustomer (params: {
                quote_id: $quote_id,
                type: $type,
                to: $to,
                cc: $cc,
                bcc: $bcc,
                subject: $subject,
                item_id: $item_id,
                isLossFactorVisible: $isLossFactorVisible
              }) {
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: payload,
        }).then(result => {
          state.commit('newProcess', {
            id: payload.quote_id,
            name: `Mengirim Email Quote ${payload.quote_id}`,
            status: 'success',
          })
          resolve(result)
        }).catch(err => {
          if (err.includes('expired')) {
            state.dispatch('signOut')
            errorModal('Harap login kembali')
          }
          state.commit('newProcess', {
            id: payload.quote_id,
            name: `Mengirim Email Quote ${payload.quote_id}`,
            status: 'error',
          })
          errorModal(err)
          reject(err)
        })
      })
    },
    previewSendEmailCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{
              previewSendEmailCustomer(quote_id: ${payload})
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.previewSendEmailCustomer)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
  },
}
