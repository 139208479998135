import Vue from 'vue'
import { createHelpers } from 'vuex-map-fields'
import { apolloClient } from '@/vue-apollo'
import { addOAuth2Token, signOutOAuth2Token } from '@/graphql/mutation'
import { checkOAuth2Token } from '@/graphql/query'
import errorHandling from '../error-handling'

const vue = new Vue()

const { getGoogleField, updateGoogleField } = createHelpers({
  getterType: 'getGoogleField',
  mutationType: 'updateGoogleField',
})

export default {
  state: {
    currentUser: '',
    isSignedIn: false,
    loadingSignIn: false,
    isInit: false,
    authCode: '',
  },
  getters: {
    getGoogleField,
  },
  mutations: {
    updateGoogleField,
  },
  actions: {
    async checkToken(state) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: checkOAuth2Token,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          if (result.data.checkOAuth2Token.isValid) {
            state.commit('updateGoogleField', { path: 'currentUser', value: result.data.checkOAuth2Token.email })
            state.commit('updateGoogleField', { path: 'isSignedIn', value: true })
            state.commit('updateGoogleField', { path: 'authCode', value: 'dummyauthcode' })
            state.commit('updateGoogleField', { path: 'loadingSignIn', value: false })
          } else {
            state.dispatch('signOut')
          }

          resolve(result.data.checkOAuth2Token.isValid)
        }).catch(err => {
          reject(err)
        })
      })
    },
    async signIn(state) {
      state.commit('updateGoogleField', { path: 'loadingSignIn', value: true })
      await vue.$gAuth.signIn().then(response => {
        state.commit('updateGoogleField', { path: 'currentUser', value: response.getBasicProfile().getEmail() })
        state.commit('updateGoogleField', { path: 'isSignedIn', value: true })
        state.commit('updateGoogleField', { path: 'loadingSignIn', value: false })
      }).catch(() => {
        state.commit('updateGoogleField', { path: 'loadingSignIn', value: false })
      })
    },
    async getAuthCode(state) {
      state.commit('updateGoogleField', { path: 'loadingSignIn', value: true })
      await vue.$gAuth.getAuthCode().then(authCode => {
        apolloClient.mutate({
          mutation: addOAuth2Token,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            user_id: state.rootGetters.getCurrentUser.user.id,
            authCode,
            email: state.state.currentUser,
          },
        }).then(() => {
          localStorage.setItem('authCode', authCode)
          state.commit('updateGoogleField', { path: 'authCode', value: authCode })
          state.commit('updateGoogleField', { path: 'loadingSignIn', value: false })
        }).catch(err => {
          errorHandling(err)
          state.commit('updateGoogleField', { path: 'loadingSignIn', value: false })
        })
      }).catch(() => {
        state.commit('updateGoogleField', { path: 'loadingSignIn', value: false })
      })
    },
    async signOut(state) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: signOutOAuth2Token,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(() => {
          vue.$gAuth.signOut()
          localStorage.removeItem('authCode')
          state.commit('updateGoogleField', { path: 'authCode', value: '' })
          state.commit('updateGoogleField', { path: 'currentUser', value: '' })
          state.commit('updateGoogleField', { path: 'isSignedIn', value: false })
          resolve()
        }).catch(err => {
          errorHandling(err)
          reject(err)
        })
      })
    },
  },
}
