import gql from 'graphql-tag'

const salesOrderCategory = gql`
  query salesOrderCategory{
    salesOrderCategory {
      id
      name
    }
  }
`
export default salesOrderCategory
