import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import permission from '@/permission'
/* eslint-disable import/no-cycle */
import router from '@/router'
import jwtDecode from 'jwt-decode'
import { createHelpers } from 'vuex-map-fields'
import { removeProfilePicture, removeTTD } from '@/graphql/mutation'
import errorModal from '../error-handling'

const { getUsersField, updateUsersField } = createHelpers({
  getterType: 'getUsersField',
  mutationType: 'updateUsersField',
})

export default {
  state: {
    currentUser: '',
    permission: '',
    loadingUser: true,
    userList: [],
    loadingNotification: true,
    notificationList: [],
    notificationCount: 0,
    sortOptions: [
      {
        label: 'Nama User ↓',
        field: 'name',
        sortType: 'asc',
      },
      {
        label: 'Nama User ↑',
        field: 'name',
        sortType: 'desc',
      },
      {
        label: 'Omzet Rupiah 1 ↓',
        field: 'target_rupiah',
        sortType: 'asc',
      },
      {
        label: 'Omzet Rupiah 1 ↑',
        field: 'target_rupiah',
        sortType: 'desc',
      },
      {
        label: 'Omzet Liter 1 ↓',
        field: 'target_liter',
        sortType: 'asc',
      },
      {
        label: 'Omzet Liter 1 ↑',
        field: 'target_liter',
        sortType: 'desc',
      },
      {
        label: 'Omzet Rupiah 2 ↓',
        field: 'target_rupiah_2',
        sortType: 'asc',
      },
      {
        label: 'Omzet Rupiah 2 ↑',
        field: 'target_rupiah_2',
        sortType: 'desc',
      },
      {
        label: 'Omzet Liter 2 ↓',
        field: 'target_liter_2',
        sortType: 'asc',
      },
      {
        label: 'Omzet Liter 2 ↑',
        field: 'target_liter_2',
        sortType: 'desc',
      },
    ],
    filterUser: {
      searchKeywords: '',
      offset: 1,
      limit: 25,
      selectedSort: {
        label: 'Nama User ↓',
        field: 'name',
        sortType: 'asc',
      },
      status: '',
      role: [],
      target_rupiah: {
        min: '',
        max: '',
      },
      target_liter: {
        min: '',
        max: '',
      },
      target_rupiah_2: {
        min: '',
        max: '',
      },
      target_liter_2: {
        min: '',
        max: '',
      },
    },
  },
  getters: {
    getUsersField,
    getCurrentUser: state => state.currentUser,
    getUserPermission: state => state.permission,
    getLoadingUser: state => state.loadingUser,
    getUserList: state => state.userList,
    getNotificationCount: state => state.notificationCount,
    getLoadingNotification: state => state.loadingNotification,
    getNotificationList: state => state.notificationList,
    getBearerToken: state => {
      if (state.currentUser) {
        return `Bearer ${state.currentUser.token}`
      }

      return ''
    },
    getCurrentRole: state => {
      if (state.currentUser) {
        return state.currentUser.user.role.id
      }

      return 1
    },
    getRefDB: state => {
      if (state.currentUser) {
        return state.currentUser.user.reference_db.id
      }

      return 0
    },
  },
  mutations: {
    updateUsersField,
    setLoadingNotification(state, payload) {
      state.loadingNotification = payload
    },
    setNotificationCount(state, payload) {
      state.notificationCount = payload
    },
    setNotificationList(state, payload) {
      state.notificationList = payload
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload
    },
    setCurrentUserWithoutToken(state, payload) {
      state.currentUser.user = payload
    },
    setPermission(state, payload) {
      state.permission = payload
    },
    clearUser(state) {
      state.currentUser = ''
      state.permission = ''
    },
    setUserList(state, payload) {
      state.userList = payload
    },
  },
  actions: {
    login(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{
              login(
                phone: "${payload.phone}",
                password: "${payload.password}"
              ){
                user {
                  id
                  phone
                  email
                  name
                  role {
                    id
                    name
                  }
                  photo
                  ttd
                  status
                  target_rupiah
                  target_liter
                  target_rupiah_2
                  target_liter_2
                  reference_accurate_id_1
                  reference_accurate_id_2
                  reference_db {
                    id
                    name
                  }
                }
                token
                refreshToken
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.commit('setCurrentUser', result.data.login)
          localStorage.setItem('user', JSON.stringify(result.data.login))
          state.dispatch('updateSelectedDB')
          state.dispatch('getPermission')
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async logout(state) {
      localStorage.removeItem('user')
      state.commit('clearUser')
      router.replace({ name: 'login' })
    },
    clearDeviceId(state) {
      const deviceId = localStorage.getItem('deviceId')
      apolloClient.mutate({
        mutation: gql`
          mutation{
            logout(device_id: "${deviceId}") {
              status
              message
            }
          }
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      })
    },
    setUserdata(state) {
      const user = JSON.parse(localStorage.getItem('user'))

      if (user) {
        // init state from local storage
        state.commit('setCurrentUser', user)

        // re init state from online data
        state.dispatch('getDetailUser', +user.user.id).then(result => {
          const userData = {
            ...user,
            user: result.data.users.users[0],
          }

          localStorage.setItem('user', JSON.stringify(userData))
          state.commit('setCurrentUser', userData)
        })

        state.dispatch('updateSelectedDB')
        state.dispatch('getPermission')
      }

      state.dispatch('checkExpiredToken')
    },
    updateUserData(state) {
      state.dispatch('getDetailUser', state.rootGetters.getCurrentUser.user.id).then(detail => {
        const newdata = detail.data.users.users[0]
        const user = JSON.parse(localStorage.getItem('user'))
        const newUser = {
          ...user,
          user: newdata,
        }

        localStorage.setItem('user', JSON.stringify(newUser))
        state.commit('setCurrentUser', newUser)
      })
    },
    getPermission(state) {
      const userPermission = {}
      const { user } = state.getters.getCurrentUser
      Object.keys(permission.access).forEach(key => {
        userPermission[key] = permission.access[key].includes(user.role.id)
      })

      state.commit('setPermission', userPermission)
    },
    getAllUser(state, payload) {
      state.commit('updateUsersField', { path: 'loadingUser', value: true })
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{users(
              filter: ${payload.filter}
            ){
              count
              users {
                id
                phone
                email
                name
                photo
                role {
                  id
                  name
                }
                ttd
                status
                target_rupiah
                target_liter
                target_rupiah_2
                target_liter_2
                reference_accurate_id_1
                reference_accurate_id_2
                reference_db {
                  id
                  name
                }
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.commit('updateUsersField', { path: 'loadingUser', value: false })
          state.commit('setUserList', result.data.users.users)
          resolve(result.data.users.users)
        }).catch(err => {
          errorModal(err)
          state.commit('updateUsersField', { path: 'loadingUser', value: false })
          reject(err)
        })
      })
    },
    activeDeactiveUser(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{activateDeactivateUser(
              user_id: ${payload}
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    addNewUser(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{register(params: {
              name: "${payload.name}"
              email: "${payload.email}"
              phone: "62${payload.phone}"
              password: "${payload.password}"
            }){
              id
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async checkExpiredToken(state) {
      const user = JSON.parse(localStorage.getItem('user'))
      const decodedToken = user ? jwtDecode(user.token) : ''
      const now = new Date()
      const exp = new Date(decodedToken.exp * 1000 - 1000000)
      if (now > exp) {
        await state.dispatch('refreshToken', {
          id: user.user.id,
          refreshToken: user.refreshToken,
        })
      }
    },
    async refreshToken(state, payload) {
      await apolloClient.mutate({
        mutation: gql`
          mutation{refreshToken(
            id: ${payload.id}
            refreshToken: "${payload.refreshToken}"
          ){
            user {
              id
              phone
              email
              name
              role {
                id
                name
              }
              photo
              ttd
              status
              target_rupiah
              target_liter
              target_rupiah_2
              target_liter_2
              reference_accurate_id_1
              reference_accurate_id_2
              reference_db {
                id
                name
              }
            }
            token
            refreshToken
          }}
        `,
      }).then(result => {
        state.commit('setCurrentUser', result.data.refreshToken)
        localStorage.setItem('user', JSON.stringify(result.data.refreshToken))
        state.dispatch('getPermission')
        router.go(0)
      }).catch(err => {
        errorModal(err)
        state.dispatch('logout')
      })
    },
    getDetailUser(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{users(
              filter: {
                id: ${payload}
              }
            ){
              count
              users {
                id
                phone
                email
                name
                photo
                role {
                  id
                  name
                }
                ttd
                status
                target_rupiah
                target_liter
                target_rupiah_2
                target_liter_2
                reference_accurate_id_1
                reference_accurate_id_2
                reference_db {
                  id
                  name
                }
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getCustomerAssigned(state, payload) {
      const search = payload.search ? `search: "${payload.search}"` : ''
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{customerAssigned(
              user_id: ${payload.id}
              ${search}
            ){
              id
              name
              customerNo
              mobilePhone
              workPhone
              npwpNo
              email
              customerLimitAmountValue
              priceCategory
              category
              isInAccurate
              isAssigned
              defaultSalesDisc
            }}
          `,
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result.data.customerAssigned)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getPerformancePenjualanUser(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{performancePenjualanUser (user_id: ${payload.user_id}, filter: {
              customer_id: ${payload.customer_id ? payload.customer_id : null}
              date: ${payload.date ? payload.date : null}
              item_id: ${payload.item_id ? payload.item_id : null}
            }){
              accumulation_count
              accumulation_total
              accumulation_total_liter
              accumulation_total_hpp
              detail_performance{
                month
                count
                total
                total_hpp
                total_liter
              }
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.performancePenjualanUser)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getNotifications(state, payload) {
      state.commit('setLoadingNotification', true)
      apolloClient.query({
        query: gql`
          query{notifications(
            pagination: {
              limit: ${payload.limit}
              offset: ${payload.offset}
            }
          ){
            count
            notifications {
              id
              reference_id
              message
              category {
                id
                name
              }
              project {
                id
                category{
                  id
                  name
                }
                customer_id
                name
                tgl_reminder
                status
              }
              created_at
              is_read
              info
            }
          }}
        `,
        fetchPolicy: 'no-cache',
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
      }).then(result => {
        state.commit('setNotificationCount', result.data.notifications.count)
        if (result.data.notifications.notifications.length) {
          state.commit('setNotificationList', result.data.notifications.notifications.map(data => ({
            ...data,
            info: data.info ? JSON.parse(data.info) : null,
          })))
        }
        state.commit('setLoadingNotification', false)
      }).catch(err => {
        errorModal(err)
        state.commit('setLoadingNotification', false)
      })
    },
    readNotification(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{
              readNotifications(notif_id: ${payload}) {
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
    readAllNotifications(state) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{
              readAllNotifications {
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    assignCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation {
              assignCustomer (
                user_id: ${payload.user_id}
                customer_id: ${payload.customer_id}
              ) {
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    updateUser(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{updateUser(
              param: {
                name: "${payload.name}",
                email: "${payload.email}",
              }
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          const user = JSON.parse(localStorage.getItem('user'))
          const newUser = {
            ...user,
            user: {
              ...user.user,
              name: payload.name,
              email: payload.email,
            },
          }

          localStorage.setItem('user', JSON.stringify(newUser))
          state.commit('setCurrentUser', newUser)
          state.dispatch('getPermission')

          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async updateProfilePicture(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $files: [Upload]!
            ){updateProfilePicture(
              files: $files
            ){
              status
              message
            }}
          `,
          variables: {
            files: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
            fetchOptions: {
              useUpload: true,
              onProgress: ev => {
                state.commit('uploadProgress', {
                  name: payload.name,
                  progress: Math.round((ev.loaded / ev.total) * 100),
                })
              },
            },
          },
        }).then(result => {
          state.dispatch('updateUserData')
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async updateTTD(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $files: Upload!
            ){updateTTD(
              file: $files
            ){
              status
              message
            }}
          `,
          variables: {
            files: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
            fetchOptions: {
              useUpload: true,
              onProgress: ev => {
                state.commit('uploadProgress', {
                  name: payload.name,
                  progress: Math.round((ev.loaded / ev.total) * 100),
                })
              },
            },
          },
        }).then(result => {
          state.dispatch('updateUserData')

          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async deleteTTD(state) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: removeTTD,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(() => {
          state.dispatch('updateUserData')
          resolve()
        }).catch(err => reject(err))
      })
    },
    async deleteProfilePicture(state) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: removeProfilePicture,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(() => {
          state.dispatch('updateUserData')
          resolve()
        }).catch(err => reject(err))
      })
    },
    async changePassword(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{changePassword(
              oldPassword: "${payload.oldPassword}"
              newPassword: "${payload.newPassword}"
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    resetPassword(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{resetPassword(
              user_id: ${payload.user_id},
              newPassword: "${payload.newPassword}"
            ){
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getTargetSales(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{
              targetSales(user_id: ${payload}) {
                target_date
                target_rupiah
                target_liter
                target_rupiah_2
                target_liter_2
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.targetSales)
        }).catch(err => {
          reject(err)
        })
      })
    },
    setTargetSales(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $target_sales: [targetSales]
            ){
              setTargetSales(user_id: ${payload.user_id}, param: $target_sales) {
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            target_sales: payload.target,
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUserByManager(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation{
              updateUserByManager (
                user_id: ${payload.user_id},
                params: {
                  reference_accurate_id_1: ${payload.reference_accurate_id_1},
                  reference_accurate_id_2: ${payload.reference_accurate_id_2},
                  phone: ${payload.phone},
                  role_id: ${payload.role_id},
                }
              ) {
                status
                message
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
