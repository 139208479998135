import gql from 'graphql-tag'

const myProject = gql`
  query myProject(
    $id: Int
  ){
    myProject(
      user_id: $id
    ){ 
      id
      name
      status
      tgl_reminder
    }
  }
`
export default myProject
