/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Vuexy state
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Modules
import customer from './modules/customer'
import project from './modules/project'
import items from './modules/items'
import scheme from './modules/scheme'
import quotes from './modules/quotes'
import user from './modules/user'
import task from './modules/task'
import reference from './modules/reference'
import report from './modules/report'
import salesorder from './modules/salesorder'
import dashboard from './modules/dashboard'
import oauth from './modules/oauth'
import quoteForm from './modules/quote_form'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    customer,
    project,
    items,
    scheme,
    quotes,
    user,
    task,
    reference,
    report,
    salesorder,
    dashboard,
    oauth,
    quoteForm,
  },
  strict: process.env.DEV,
})
