import gql from 'graphql-tag'

const removeTTD = gql`
mutation removeTTD {
  removeTTD{
    status
    message
  }
}
`
export default removeTTD
