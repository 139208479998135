import gql from 'graphql-tag'

const updateDryReport = gql`
  mutation updateDryReport(
    $dry_report_id: Int!
    $params: paramDryReport!
  ){
    updateDryReport(
      dry_report_id: $dry_report_id,
      params: $params
    ){
      status
      message
    }
  }
`
export default updateDryReport
