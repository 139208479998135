import gql from 'graphql-tag'

const updateScheme = gql`
  mutation updateScheme(
    $scheme_id: Int!
    $params: paramScheme!
  ){
    updateScheme(
      scheme_id: $scheme_id
      params: $params
    ){
      status
      message
    }
  }
`
export default updateScheme
