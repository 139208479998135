import { createHelpers } from 'vuex-map-fields'
import { apolloClient } from '@/vue-apollo'
import {
  dryReportDetail, dryReportInformation, dryReports, locations,
} from '@/graphql/query'
import { addDryReport, deleteDryReport, updateDryReport } from '@/graphql/mutation'

const { getReportField, updateReportField } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  state: {
    dryReport: {
      loadingDryReport: true,
      dryReportCount: 0,
      dryReportList: [],
      dryReportFilter: {
        search: '',
        selectedSort: {
          label: 'Tanggal Dibuat ↑',
          field: 'created_at',
          sortType: 'desc',
        },
        offset: 1,
        limit: 25,
        created_at: {},
        event_date: {},
        location: [],
        userCreate: [],
      },
      locationOptions: [],
      dryReportSortOptions: [
        {
          label: 'Tanggal Dibuat ↓',
          field: 'created_at',
          sortType: 'asc',
        },
        {
          label: 'Tanggal Dibuat ↑',
          field: 'created_at',
          sortType: 'desc',
        },
        {
          label: 'Tanggal Mulai ↓',
          field: 'event_start',
          sortType: 'asc',
        },
        {
          label: 'Tanggal Mulai ↑',
          field: 'event_start',
          sortType: 'desc',
        },
      ],
    },
  },
  getters: {
    getReportField,
  },
  mutations: {
    updateReportField,
  },
  actions: {
    getDryReportInformation(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: dryReportInformation,
          variables: {
            quote_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.dryReportInformation)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getLocations(state) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: locations,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.locations)
        }).catch(err => {
          reject(err)
        })
      })
    },
    addDryReport(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: addDryReport,
          variables: {
            quote_id: payload.quote,
            params: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
            fetchOptions: {
              useUpload: true,
              onProgress: ev => {
                if (payload.file) {
                  state.commit('uploadProgress', {
                    name: payload.file.name,
                    progress: Math.round((ev.loaded / ev.total) * 100),
                  })
                }
              },
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateDryReport(state, payload) {
      return new Promise((resolve, reject) => {
        const {
          id,
          event_start,
          event_end,
          location_id,
          areas,
          items,
        } = payload
        apolloClient.mutate({
          mutation: updateDryReport,
          variables: {
            dry_report_id: id,
            params: {
              event_start,
              event_end,
              location_id,
              areas,
              items,
            },
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
            fetchOptions: {
              useUpload: true,
              onProgress: ev => {
                if (payload.file) {
                  state.commit('uploadProgress', {
                    name: payload.file.name,
                    progress: Math.round((ev.loaded / ev.total) * 100),
                  })
                }
              },
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getDryReports(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: dryReports,
          variables: {
            filter: payload.filter,
            pagination: payload.pagination,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.dryReports)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getDryReportDetail(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: dryReportDetail,
          variables: {
            dry_report_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.dryReportDetail)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteDryReport(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteDryReport,
          variables: {
            dry_report_id: payload,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
