import gql from 'graphql-tag'

const addDryReport = gql`
  mutation addDryReport(
    $quote_id: [Int]!
    $params: paramDryReport!
  ){
    addDryReport(
      quote_id: $quote_id
      params: $params
    ){
      id
      status
      message
    }
  }
`
export default addDryReport
