import gql from 'graphql-tag'

const addSalesOrder = gql`
  mutation addSalesOrder(
    $params: paramSalesOrder!
  ){
    addSalesOrder(
      params: $params
    ){
      id
      status
      message
    }
  }
`
export default addSalesOrder
