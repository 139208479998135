import gql from 'graphql-tag'

const updateProject = gql`
  mutation updateProject(
    $project_id: Int!
    $params: paramProject!
  ){
    updateProject(
      project_id: $project_id
      params: $params
    ){
      status
      message
    }
  }
`
export default updateProject
