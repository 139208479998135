import gql from 'graphql-tag'

const changeReferenceDB = gql`
  mutation changeReferenceDB(
    $reference_db: Int!
  ){
    changeReferenceDB(
      reference_db: $reference_db
    ) {
      status
      message
    }
  }
`
export default changeReferenceDB
