import gql from 'graphql-tag'

const salesAccurate = gql`
  query salesAccurate(
    $ref_db: [Int]!
  ){
    salesAccurate (
      ref_db: $ref_db
    ) {
      id
      name
    }
  }
`
export default salesAccurate
