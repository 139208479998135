import gql from 'graphql-tag'

const projects = gql`
  query projects(
    $filter: filterProject
    $pagination: Pagination
  ){
    projects(
      filter: $filter
      pagination: $pagination
    ){
      count
      projects {
        id
        name
        customer_id
        category {
          id
          name
        }
        status
        lastQuote {
          id
          name
          total
          status{
            id
            name
          }
          created_at
          closed_at
          updated_at
          version
          valid_until
          total_hpp
          sales_order_id
          dry_report_id
          userCreate {
            id
            name
            role {
              id
              name
            }
            photo
          }
        }
        tgl_reminder
      }
    }
  }
`
export default projects
