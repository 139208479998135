import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import VueSweetAlert2 from 'vue-sweetalert2'
import 'animate.css'
import 'regenerator-runtime/runtime'
import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core' // Import css here if you haven't elsewhere
import Ripple from 'vue-ripple-directive'
import GAuth from 'vue-google-oauth2'

import firebase from 'firebase/app'
import router from './router'
import store from './store'
import App from './App.vue'

import 'firebase/messaging'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import { createProvider } from './vue-apollo'
import './registerServiceWorker'

firebase.initializeApp({
  apiKey: 'AIzaSyBUei9KYUA_Wr79UlD47geQthRTYkXTlbo',
  authDomain: 'quotation-acc-app-8dd35.firebaseapp.com',
  projectId: 'quotation-acc-app-8dd35',
  storageBucket: 'quotation-acc-app-8dd35.appspot.com',
  messagingSenderId: '513606277930',
  appId: '1:513606277930:web:33f39488276b60a555dc53',
  measurementId: 'G-KKJ1DSQKQY',
})

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
}

Vue.prototype.moment = moment

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueAnimXYZ)

Vue.use(VueSweetAlert2, {
  showClass: {
    popup: 'animate__animated animate__fadeInUp animate__faster',
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutDown animate__faster',
  },
})

const gauthOption = {
  clientId: '768814228565-fcprrimkj4hfqd17c3f0l7i24luok65b.apps.googleusercontent.com',
  scope: 'profile email https://mail.google.com/',
  prompt: 'consent',
}
Vue.use(GAuth, gauthOption)

Vue.directive('ripple', Ripple)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$restHTTP = process.env.VUE_APP_GRAPHQL_HTTP_REST

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  computed: {
    role: () => store.getters.getCurrentRole,
  },
  render: h => h(App),
}).$mount('#app')
