import gql from 'graphql-tag'

const tasksPerformanceStats = gql`
  query tasksPerformanceStats(
    $user_id: Int!
    $period: String!
  ){
    tasksPerformanceStats(
      user_id: $user_id
      period: $period
    ) {
      period
      count
      percentage_on_time
      percentage_late
      percentage_not_done
    }
  }
`
export default tasksPerformanceStats
