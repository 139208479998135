import gql from 'graphql-tag'

const deleteScheme = gql`
  mutation deleteScheme(
    $scheme_id: Int!
  ){
    deleteScheme(
      scheme_id: $scheme_id
    ){
    status
    message
    }
  }
`
export default deleteScheme
