import { apolloClient } from '@/vue-apollo'
import { createHelpers } from 'vuex-map-fields'
import {
  areaCategory, performanceQuotesProject, projectCategory, projectDetail, projects,
} from '@/graphql/query'
import {
  addProject, deleteProject, restoreProject, updateProject, updateProjectReminder,
} from '@/graphql/mutation'
import errorModal from '../error-handling'

const { getProjectsField, updateProjectsField } = createHelpers({
  getterType: 'getProjectsField',
  mutationType: 'updateProjectsField',
})

export default {
  state: {
    loadingProject: true,
    projectList: [],
    projectCategory: [],
    projectCount: 0,
    offset: 0,
    limit: 25,
    limitOptions: [25, 50, 100, 200],
    sortOptions: [
      {
        label: 'Nama Project ↓',
        field: 'name',
        sortType: 'asc',
      },
      {
        label: 'Nama Project ↑',
        field: 'name',
        sortType: 'desc',
      },
      {
        label: 'Tanggal Quote Terakhir ↓',
        field: 'qcreated_at',
        sortType: 'asc',
      },
      {
        label: 'Tanggal Quote Terakhir ↑',
        field: 'qcreated_at',
        sortType: 'desc',
      },
      {
        label: 'Total Quote Terakhir ↓',
        field: 'qtotal',
        sortType: 'asc',
      },
      {
        label: 'Total Quote Terakhir ↑',
        field: 'qtotal',
        sortType: 'desc',
      },
      {
        label: 'Tgl Reminder ↓',
        field: 'tgl_reminder',
        sortType: 'asc',
      },
      {
        label: 'Tgl Reminder ↑',
        field: 'tgl_reminder',
        sortType: 'desc',
      },
    ],
    limitSelection: [25, 50, 100, 200],
    filterProjects: {
      category: [],
      status: null,
      customer_id: [],
      tgl_reminder: {},
      searchKeywords: '',
      offset: 1,
      limit: 25,
      selectedSort: {
        label: 'Sortir',
        field: 'id',
        sortType: 'asc',
      },
    },
  },
  getters: {
    getProjectsField,
    getProjectList: state => state.projectList,
    getProjectCount: state => state.projectCount,
    getSortOptions: state => state.sortOptions,
    getProjectCategory: state => state.projectCategory,
  },
  mutations: {
    updateProjectsField,
    setProjectList(state, payload) {
      state.projectList = payload
    },
    setProjectCount(state, payload) {
      state.projectCount = payload
    },
    setProjectCategory(state, payload) {
      state.projectCategory = payload
    },
  },
  actions: {
    async getAllProject(state, payload) {
      state.commit('updateProjectsField', { path: 'loadingProject', value: true })
      await apolloClient.query({
        query: projects,
        variables: {
          filter: payload.filter,
          pagination: payload.pagination,
        },
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('updateProjectsField', { path: 'loadingProject', value: false })
        state.commit('setProjectList', result.data.projects.projects)
        state.commit('setProjectCount', result.data.projects.count)
      }).catch(err => {
        errorModal(err)
        state.commit('updateProjectsField', { path: 'loadingProject', value: false })
      })
    },
    async getProjectCategory(state) {
      await apolloClient.query({
        query: projectCategory,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setProjectCategory', result.data.projectCategory)
      }).catch(err => {
        errorModal(err)
      })
    },
    async addProject(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: addProject,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            params: {
              customer_id: payload.customerId,
              name: payload.projectName,
              project_category: payload.category,
              tgl_reminder: payload.reminderDate,
              surface_area: payload.surfaceArea,
            },
          },
        }).then(result => {
          resolve(result.data.addProject)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async updateProject(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: updateProject,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            project_id: payload.id,
            params: {
              customer_id: payload.customerId,
              name: payload.projectName,
              project_category: payload.category,
              tgl_reminder: payload.reminderDate,
              surface_area: payload.surfaceArea,
            },
          },
        }).then(result => {
          resolve(result.data.updateProject)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    updateProjectReminder(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: updateProjectReminder,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: payload,
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getProjectDetail(state, id) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: projectDetail,
          variables: {
            project_id: id,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getAreaCategory(state, id) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: areaCategory,
          variables: {
            project_category: id,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async deleteProject(state, id) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: deleteProject,
          variables: {
            project_id: id,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async restoreProject(state, id) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: restoreProject,
          variables: {
            project_id: id,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getProjectCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        const sort = payload.sort ? payload.sort : null
        const sortType = payload.sortType ? payload.sortType : null
        apolloClient.query({
          query: projects,
          variables: {
            filter: {
              customer_id: payload.id,
              sort,
              sortType,
            },
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.projects.projects)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getPerformanceQuotesProject(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: performanceQuotesProject,
          variables: {
            project_id: payload.project_id,
            period: payload.period,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.performanceQuotesProject)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
  },
}
