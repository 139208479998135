import gql from 'graphql-tag'

const locations = gql`
  query locations{
    locations{
      id
      name
      city {
        id
        name
      }
    }
  }
`
export default locations
