import { apolloClient } from '@/vue-apollo'
import { createHelpers } from 'vuex-map-fields'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { changeReferenceDB, updateFCMToken } from '@/graphql/mutation'
import {
  globalSearch, referenceDB, roles, salesAccurate,
} from '@/graphql/query'
import errorModal from '../error-handling'

const { getDBField, updateDBField } = createHelpers({
  getterType: 'getDBField',
  mutationType: 'updateDBField',
})

export default {
  state: {
    loadingReferenceDB: true,
    referenceDBList: [],
    selectedDB: '',
    uploadList: [],
    salesAccurate1: [],
    salesAccurate2: [],
    roles: [],
    processList: [],
  },
  getters: {
    getDBField,
    getLoadingReferenceDB: state => state.loadingReferenceDB,
    getReferenceDBList: state => state.referenceDBList,
    getSelectedDB: state => state.selectedDB,
  },
  mutations: {
    updateDBField,
    setReferenceDBList(state, payload) {
      state.referenceDBList = payload
    },
    setLoadingReferenceDB(state, payload) {
      state.loadingReferenceDB = payload
    },
    setSelectedDB(state, payload) {
      state.selectedDB = payload
    },
    uploadProgress(state, payload) {
      if (state.uploadList.some(el => el.name === payload.name) && !payload.error) {
        const index = state.uploadList.findIndex(el => el.name === payload.name)

        state.uploadList[index].progress = payload.progress
      } else {
        state.uploadList.push(payload)
      }

      if (payload.progress === 100 && !payload.error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `Upload ${payload.name} berhasil!`,
            icon: 'FileIcon',
            variant: 'success',
          },
        })

        setTimeout(() => {
          state.uploadList = state.uploadList.filter(el => el.name !== payload.name)
        }, 4000)
      }
    },
    newProcess(state, payload) {
      if (state.processList.some(el => el.id === payload.id)) {
        const index = state.processList.findIndex(el => el.id === payload.id)

        state.processList[index].status = payload.status
      } else {
        state.processList.push(payload)
      }

      if (payload.status === 'success') {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `${payload.name} berhasil!`,
            icon: 'SendIcon',
            variant: 'success',
          },
        })

        setTimeout(() => {
          state.processList = state.processList.filter(el => el.id !== payload.id)
        }, 4000)
      } else if (payload.status === 'error') {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `${payload.name} gagal!`,
            icon: 'SendIcon',
            variant: 'warning',
          },
        })

        setTimeout(() => {
          state.processList = state.processList.filter(el => el.id !== payload.id)
        }, 4000)
      }
    },
  },
  actions: {
    initApp(state) {
      state.dispatch('checkExpiredToken')
      state.dispatch('setUserdata')
      state.dispatch('updateFCMToken')
      state.dispatch('getTask')
      state.dispatch('getQuoteBadge')
    },
    updateFCMToken(state) {
      const deviceId = localStorage.getItem('deviceId')
      const token = localStorage.getItem('fcmToken')

      if (deviceId && token) {
        apolloClient.mutate({
          mutation: updateFCMToken,
          variables: {
            device_id: deviceId,
            token,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        })
      }
    },
    async getReferenceDBList(state) {
      state.commit('setLoadingReferenceDB', true)
      await apolloClient.query({
        query: referenceDB,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setReferenceDBList', result.data.referenceDB)
        state.commit('setLoadingReferenceDB', false)
      }).catch(err => {
        errorModal(err)
        state.commit('setLoadingReferenceDB', false)
      })
    },
    async updateSelectedDB(state) {
      if (!state.state.referenceDBList.length) {
        await state.dispatch('getReferenceDBList')
      }

      const dbid = state.rootGetters.getCurrentUser.user.reference_db.id

      if (dbid) {
        const db = state.state.referenceDBList.filter(el => el.id === dbid)[0]
        state.commit('setSelectedDB', db)
      } else {
        const db = state.state.referenceDBList[0]
        state.commit('setSelectedDB', db)
      }
    },
    changeReferenceDB(state, payload) {
      state.commit('setLoadingReferenceDB', true)
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: changeReferenceDB,
          variables: {
            reference_db: +payload.id,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          state.commit('setLoadingReferenceDB', false)

          // update selected db to state
          const db = state.state.referenceDBList.filter(el => el.id === payload.id)[0]
          state.commit('setSelectedDB', db)

          // update selected db to local storage
          const user = JSON.parse(localStorage.getItem('user'))
          const newUser = {
            ...user,
            user: {
              ...user.user,
              reference_db: db,
            },
          }
          localStorage.setItem('user', JSON.stringify(newUser))

          state.dispatch('initApp')
          resolve(result.data.changeReferenceDB)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    globalSearch(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: globalSearch,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            search: payload,
          },
        }).then(result => {
          resolve(result.data.globalSearch)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getSalesAccurate(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: salesAccurate,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            ref_db: payload,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.salesAccurate)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getRoles(state) {
      apolloClient.query({
        query: roles,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('updateDBField', { path: 'roles', value: result.data.roles })
      })
    },
  },
}
