import { createHelpers } from 'vuex-map-fields'
import moment from 'moment'

const { getQuotesFormField, updateQuotesFormField } = createHelpers({
  getterType: 'getQuotesFormField',
  mutationType: 'updateQuotesFormField',
})

export default {
  state: {
    loading: false,
    loadingAdd: false,
    selectedDiscount: '%',
    discount: '',
    quotationData: {
      id: '0000',
      issuedDate: new Date(),
      dueDate: moment(new Date()).add(1, 'M').format('YYYY-MM-DD'),
      customer: {},
      project: {},
      subtotal: 0,
      subtotalhpp: 0,
      total: 0,
      totalhpp: 0,
      diskon: 0,
      notes: '',
    },
    loadingAreaOptions: false,
    areaOptions: [],
    selectedArea: [],
    selectedAreaVModel: [],
    surfaceOptions: [],
    prevQuote: '',
    error: '',
  },
  getters: {
    getQuotesFormField,
  },
  mutations: {
    updateQuotesFormField,
  },
  actions: {
    resetFormQuoteState(state) {
      console.log('reset state')
      state.loading = false
      state.loadingAdd = false
      state.selectedDiscount = '%'
      state.discount = ''
      state.quotationData = {
        id: '0000',
        issuedDate: new Date(),
        dueDate: moment(new Date()).add(1, 'M').format('YYYY-MM-DD'),
        customer: {},
        project: {},
        subtotal: 0,
        subtotalhpp: 0,
        total: 0,
        totalhpp: 0,
        diskon: 0,
        notes: '',
      }
      state.loadingAreaOptions = false
      state.areaOptions = []
      state.selectedArea = []
      state.selectedAreaVModel = []
      state.surfaceOptions = []
      state.prevQuote = ''
      state.error = ''
    },
  },
}
