import gql from 'graphql-tag'

const dashboardPendingQuotesManager = gql`
  query dashboardPendingQuotesManager {
    dashboardPendingQuotesManager {
      all_submitted {
        id
        name
        last_version
        project {
          id
          name
          category {
            id
            name
          }
          tgl_reminder
          status
        }
        status {
          id
          name
        }
        total
        total_hpp
        created_at
        userCreate {
          id
          name
          role {
            id
            name
          }
          photo
        }
      }
      my_dashboard_quote {
        id
        name
        last_version
        project {
          id
          name
          category {
            id
            name
          }
          tgl_reminder
          status
        }
        status {
          id
          name
        }
        total
        total_hpp
        created_at
        userCreate {
          id
          name
          role {
            id
            name
          }
          photo
        }
      }
      all_dashboard_quote {
        id
        name
        last_version
        project {
          id
          name
          category {
            id
            name
          }
          tgl_reminder
          status
        }
        status {
          id
          name
        }
        total
        total_hpp
        created_at
        userCreate {
          id
          name
          role {
            id
            name
          }
          photo
        }
      }
    }
  }
`
export default dashboardPendingQuotesManager
