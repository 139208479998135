import gql from 'graphql-tag'

const areaCategory = gql`
  query areaCategory(
    $project_category: Int!
  ){
    areaCategory(
      project_category: $project_category
    ){
      id
      name
    }
  }
`
export default areaCategory
