import gql from 'graphql-tag'

const deleteCustomer = gql`
  mutation deleteCustomer(
    $customer_id: String!
  ){
    deleteCustomer(
      customer_id: $customer_id
    ){
      status
      message
    }
  }
`
export default deleteCustomer
