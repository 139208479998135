import gql from 'graphql-tag'

const shipment = gql`
  query shipment {
    shipment {
      id
      name
    }
  }
`
export default shipment
