import gql from 'graphql-tag'

const globalSearch = gql`
  query globalSearch(
    $search: String!
  ){
    globalSearch(
      search: $search
    ){
      id
      name
      type
      info
    }
  }
`
export default globalSearch
