import gql from 'graphql-tag'

const doneUndoneTask = gql`
  mutation doneUndoneTask(
    $task_id: Int!
  ){
    doneUndoneTask(task_id: $task_id) {
      status
      message
    }
  }
`
export default doneUndoneTask
