import gql from 'graphql-tag'

const tasks = gql`
  query tasks(
    $filter: filterTask
    $pagination: Pagination
  ){
    tasks(
    filter: $filter
    pagination: $pagination
    ){
      id
      description
      user {
        id
        phone
        email
        name
        role {
          id
          name
        }
        photo
        ttd
        status
      }
      project {
        id
        category {
          id
          name
        }
        customer_id
        name
        tgl_reminder
        status
      }
      due_date
      status
      tags {
        id
        name
      }
      recurring
    }
  }
`
export default tasks
