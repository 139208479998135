import gql from 'graphql-tag'

const performanceQuotesProject = gql`
  query performanceQuotesProject(
    $project_id: Int!
    $period: String!
  ){
    performanceQuotesProject (
      project_id: $project_id
      period: $period
    ){
      period
      value
      quote
      type
    }
  }
`
export default performanceQuotesProject
