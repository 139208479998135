import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import { createHelpers } from 'vuex-map-fields'
import errorModal from '@/store/error-handling'
import { items } from '@/graphql/query'
import { removeDataSheet } from '@/graphql/mutation'

const { getItemsField, updateItemsField } = createHelpers({
  getterType: 'getItemsField',
  mutationType: 'updateItemsField',
})

export default {
  state: {
    loadingItems: true,
    loadingAgent: true,
    loadingThinner: true,
    items: [],
    itemsCount: 0,
    colors: [],
    packagings: [],
    itemTypes: [],
    itemCategory: [],
    agentItems: [],
    thinner: [],
    filterItems: {
      item_type: [],
      search: '',
      category: '',
      offset: 1,
      limit: 25,
      sortOptions: [
        {
          label: 'Nama Item ↓',
          field: 'name',
          sortType: 'asc',
        },
        {
          label: 'Nama Item ↑',
          field: 'name',
          sortType: 'desc',
        }, {
          label: 'Kode Item ↓',
          field: 'no',
          sortType: 'asc',
        },
        {
          label: 'Kode Item ↑',
          field: 'no',
          sortType: 'desc',
        },
      ],
      selectedSort: {
        label: 'Nama Item ↓',
        field: 'name',
        sortType: 'asc',
      },
    },
  },
  getters: {
    getItemsField,
    getLoadingItems: state => state.loadingItems,
    getLoadingAgent: state => state.loadingAgent,
    getLoadingThinner: state => state.loadingThinner,
    getItems: state => state.items,
    getItemsCount: state => state.itemsCount,
    getColors: state => state.colors,
    getPackagingsList: state => state.packagings,
    getItemTypes: state => state.itemTypes,
    getItemCategory: state => state.itemCategory,
    getAgentList: state => state.agentItems,
    getThinnerList: state => state.thinner,
  },
  mutations: {
    updateItemsField,
    setLoadingItems(state, payload) {
      state.loadingItems = payload
    },
    setLoadingAgent(state, payload) {
      state.loadingAgent = payload
    },
    setLoadingThinner(state, payload) {
      state.loadingThinner = payload
    },
    setItems(state, payload) {
      state.items = payload
    },
    setItemsCount(state, payload) {
      state.itemsCount = payload
    },
    setColors(state, payload) {
      state.colors = payload
    },
    setItemTypes(state, payload) {
      state.itemTypes = payload
    },
    setItemCategory(state, payload) {
      state.itemCategory = payload
    },
    setPackagings(state, payload) {
      state.packagings = payload
    },
    setAgentItems(state, payload) {
      state.agentItems = payload
    },
    setThinner(state, payload) {
      state.thinner = payload
    },
  },
  actions: {
    async getAllItems(state) {
      state.commit('setLoadingItems', true)
      const { filterItems } = state.state
      const search = filterItems.search ? filterItems.search : null
      const itemType = filterItems.item_type.length ? filterItems.item_type.map(value => value.id) : null
      console.log(itemType)
      await apolloClient.query({
        query: items,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            search,
            item_category: filterItems.category ? filterItems.category.id : null,
            sort: filterItems.selectedSort.field,
            sortType: filterItems.selectedSort.sortType,
            item_type: itemType,
          },
          pagination: {
            limit: filterItems.limit,
            offset: filterItems.offset,
          },
        },
      }).then(result => {
        state.commit('setLoadingItems', false)
        state.commit('setItems', result.data.items.items)
        state.commit('setItemsCount', result.data.items.count)
      }).catch(err => {
        state.commit('setLoadingItems', false)
        errorModal(err)
      })
    },
    getItemsDetail(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{itemDetail(item_id: ${payload}){
              id
              balance
              availableToSell
              name
              no
              type {
                id
                name
              }
              itemCategory{
                id
                name
              }
              detailSellingPrice {
                priceCategory {
                  id
                  name
                }
                price
              }
              hpp
              vs_volume_solid
              color {
                id_ral
                hex_code
                ind_name
                eng_name
              }
              packaging_name
              liter
              agent_item_id
              agent_item_2_id
              ratio_agent
              ratio_agent_2
              recommended_thinner_id
              ratio_recommended_thinner_id
              type {
                id
                name
              }
              unit2Name
              unit3Name
              ratio2
              ratio3
              data_sheet
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.itemDetail)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getItemsDetailCustomer(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query{itemDetail(item_id: ${payload.item_id}, customer_id: "${payload.customer_id.toString()}"){
              id
              balance
              availableToSell
              name
              no
              type {
                id
                name
              }
              itemCategory{
                id
                name
              }
              detailSellingPrice {
                priceCategory {
                  id
                  name
                }
                price
              }
              hpp
              vs_volume_solid
              color {
                id_ral
                hex_code
                ind_name
                eng_name
              }
              packaging_name
              liter
              agent_item_id
              ratio_agent
              agent_item_2_id
              ratio_agent_2
              recommended_thinner_id
              ratio_recommended_thinner_id
              type {
                id
                name
              }
              unit2Name
              unit3Name
              ratio2
              ratio3
              data_sheet
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.itemDetail)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async addItem(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $id: Int!
              $vs_volume_solid: Float
              $color: String
              $packaging: Int!
              $liter: Float
            ){addItem(params: {
              id: $id
              vs_volume_solid: $vs_volume_solid
              color: $color
              packaging: $packaging
              liter: $liter
            }){
              id
              status
              message
            }}
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          variables: {
            id: payload.id,
            vs_volume_solid: payload.vs,
            color: payload.color,
            packaging: payload.packaging,
            liter: payload.liter,
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    async uploadDataSheet(state, payload) {
      return new Promise((resolve, reject) => {
        console.log('run')
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $item_id: Int!
              $files: Upload!
            ){uploadDataSheet(
              item_id: $item_id
              file: $files
            ){
              status
              message
            }}
          `,
          variables: {
            item_id: payload.item_id,
            files: payload.file,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
            fetchOptions: {
              useUpload: true,
              onProgress: ev => {
                state.commit('uploadProgress', {
                  name: payload.file.name,
                  progress: Math.round((ev.loaded / ev.total) * 100),
                  error: false,
                })
              },
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          state.commit('uploadProgress', {
            name: payload.file.name,
            progress: 0,
            error: true,
          })
          errorModal('Upload file gagal, harap upload kembali!')
          reject(err)
        })
      })
    },
    async removeDataSheet(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: removeDataSheet,
          variables: {
            item_id: payload.item_id,
          },
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result)
        }).catch(err => {
          errorModal('Hapus file gaga!')
          reject(err)
        })
      })
    },
    addItems(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $id: Int!
              $vs_volume_solid: Float
              $color_id_ral: String
              $packaging: Int!
              $liter: Float
              $agent_item_id: Int
              $ratio_agent: Float
              $agent_item_2_id: Int
              $ratio_agent_2: Float
              $recommended_thinner_id: Int
              $ratio_recommended_thinner_id: Float
              $item_type: Int!
            ){addItem(params: {
              id: $id
              vs_volume_solid: $vs_volume_solid
              color_id_ral: $color_id_ral
              packaging: $packaging
              liter: $liter
              agent_item_id: $agent_item_id
              ratio_agent: $ratio_agent
              agent_item_2_id: $agent_item_2_id
              ratio_agent_2: $ratio_agent_2
              recommended_thinner_id: $recommended_thinner_id
              ratio_recommended_thinner_id: $ratio_recommended_thinner_id
              item_type: $item_type
            }){
              status
              message
            }}
          `,
          variables: payload,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result.data.addItem)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    updateItems(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.mutate({
          mutation: gql`
            mutation(
              $vs_volume_solid: Float
              $color_id_ral: String
              $packaging: Int
              $liter: Float
              $agent_item_id: Int
              $ratio_agent: Float
              $agent_item_2_id: Int
              $ratio_agent_2: Float
              $recommended_thinner_id: Int
              $ratio_recommended_thinner_id: Float
              $item_type: Int!
            ){updateItem(item_id: ${payload.item_id}, params: {
              vs_volume_solid: $vs_volume_solid
              color_id_ral: $color_id_ral
              packaging: $packaging
              liter: $liter
              agent_item_id: $agent_item_id
              ratio_agent: $ratio_agent
              agent_item_2_id: $agent_item_2_id
              ratio_agent_2: $ratio_agent_2
              recommended_thinner_id: $recommended_thinner_id
              ratio_recommended_thinner_id: $ratio_recommended_thinner_id
              item_type: $item_type
            }){
              status
              message
            }}
          `,
          variables: payload,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
        }).then(result => {
          resolve(result.data.updateItem)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
    getColors(state) {
      apolloClient.query({
        query: gql`
          query{ralColors{
            id_ral
            hex_code
            ind_name
            eng_name
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setColors', result.data.ralColors)
      }).catch(err => {
        errorModal(err)
      })
    },
    async getPackagings(state) {
      await apolloClient.query({
        query: gql`
          query{packagings{
            id
            name
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setPackagings', result.data.packagings)
      }).catch(err => {
        errorModal(err)
      })
    },
    async getTypes(state) {
      await apolloClient.query({
        query: gql`
          query{itemTypes{
            id
            name
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setItemTypes', result.data.itemTypes)
      }).catch(err => {
        errorModal(err)
      })
    },
    getCategories(state) {
      apolloClient.query({
        query: gql`
          query{itemCategory{
            id
            name
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setItemCategory', result.data.itemCategory)
      }).catch(err => {
        errorModal(err)
      })
    },
    getAgentItems(state) {
      state.commit('setLoadingAgent', true)
      apolloClient.query({
        query: gql`
          query{items(filter: {
            item_type: 2
          }){
            count
            items{
              id
              balance
              availableToSell
              name
              no
              itemCategory {
                id
                name
              }
              vs_volume_solid
              color {
                id_ral
                hex_code
                ind_name
                eng_name
              }
              packaging_name
              liter
              agent_item_id
              recommended_thinner_id
              ratio_recommended_thinner_id
              type {
                id
                name
              }
              isInDB
            }
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setLoadingAgent', false)
        state.commit('setAgentItems', result.data.items.items)
      }).catch(err => {
        errorModal(err)
      })
    },
    getThinner(state) {
      state.commit('setLoadingThinner', true)
      apolloClient.query({
        query: gql`
          query{items(filter: {
            item_type: 3
          }){
            count
            items{
              id
              balance
              availableToSell
              name
              no
              itemCategory {
                id
                name
              }
              vs_volume_solid
              color {
                id_ral
                hex_code
                ind_name
                eng_name
              }
              packaging_name
              liter
              agent_item_id
              recommended_thinner_id
              ratio_recommended_thinner_id
              type {
                id
                name
              }
              isInDB
            }
          }}
        `,
        context: {
          headers: {
            Authorization: state.rootGetters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.commit('setLoadingThinner', false)
        state.commit('setThinner', result.data.items.items)
      }).catch(err => {
        errorModal(err)
      })
    },
    getPerformanceQuotesItem(state, payload) {
      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query {
              performanceQuotesItem (
                item_id: ${payload.item_id}
                period: "${payload.period}"
              ) {
                period
                value
                quote
                type
              }
            }
          `,
          context: {
            headers: {
              Authorization: state.rootGetters.getBearerToken,
            },
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          resolve(result.data.performanceQuotesItem)
        }).catch(err => {
          errorModal(err)
          reject(err)
        })
      })
    },
  },
}
